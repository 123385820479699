import React from 'react'

function AtServiceReadMore(props) {
    const {readmoreclassName}=props;
  return (
    <>
      <p>
        Read More
        <i className={readmoreclassName}></i>
      </p>
    </>
  )
}

export default AtServiceReadMore
