import React from 'react'
import HeaderSticky from './HeaderSticky';
import Submenu from './DreamspaceComponents/Submenu';
import Gen1 from './DreamspaceComponents/Gen1';
import HowItWorks from './HomeComponents/HowItWorks';
import AiSupported from './DreamspaceComponents/AiSupported';
import IntroducingAugrade from './DreamspaceComponents/IntroducingAugrade';
import DreamspaceFeatures from './DreamspaceComponents/DreamspaceFeatures';
import Render3d from './DreamspaceComponents/Render3d';
import Integrated from './DreamspaceComponents/Integrated';
import Quality from './DreamspaceComponents/Quality';
import Delivering from './HomeComponents/Delivering';
import SpatialComputing from './DreamspaceComponents/SpatialComputing';
import Workingdesktop from './HomeComponents/workingdesktop';
import Workingmobile from './HomeComponents/workingmobile';
import Scale from './DreamspaceComponents/Scale';
import QnA from './DreamspaceComponents/QnA';
// import WaitingFor from './DreamspaceComponents/WaitingFor';
// import TryIt from './DreamspaceComponents/TryIt';

export default function Dreamspace() {

  
    function beforeAfter() {
        document.getElementById('compare').style.width = document.getElementById('slider').value + "%";
      }
  return (
    <div>
      <HeaderSticky />

      <Submenu/>

      <Gen1/>

      <AiSupported/>

      <Workingdesktop/>

      <Workingmobile/>

      {/* <HowItWorks/> */}

      <IntroducingAugrade/>

      {/* <DreamspaceFeatures/> */}
      {/* <Render3d/> */}
      <Scale/> <br/>

      <Integrated/>

      {/* <Quality/> */}

      <Delivering/>      

      {/* <SpatialComputing/> */}
      {/* <WaitingFor/>

      <TryIt/> */}

      <QnA/>

      {/* <div className="mobilefullbanner">
        <div className="container-fluid py-5 mt-5 mb-5 ">
          <div className="container">
            <div className="atservicebanner">
              <div
                className="text-center mx-auto wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h3 className="hfs48black">What are you waiting for?</h3>
              </div>
              <div
                className="row g-5 waitrowmobile"
                style={{ marginTop: "3rem" }}
              >
                <div
                  className="col-lg-6 pb-5 bg-box-shadow wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div
                    className="text-center mx-auto wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <h3 className="pfs28roboto">Try Dreamspace, for free</h3>
                  </div>
                  <form className="waitingfor text-center mt-3 mb-3">
                    <div className="form-group">
                      <label for="exampleFormControlInput1" className="mb-3">
                        Upload a sketch/3D model & push the limits
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="customFile"
                      />
                    </div>
                  </form>
                </div>

                <div
                  className="col-lg-6 pb-5 bg-box-shadow wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div
                    className="text-center mx-auto wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <h3 className="pfs28roboto">Talk to Our Team</h3>
                  </div>
                  <form className="waitingfor text-center mt-3 mb-3">
                    <div className="form-group row">
                      <label
                        for="inputName"
                        className="col-sm-4 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="Please enter your full name"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        for="inputEmail"
                        className="col-sm-4 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmail"
                          placeholder="Please enter your email id"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        for="inputMobile"
                        className="col-sm-4 col-form-label"
                      >
                        Mobile
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="inputMobile"
                          placeholder="Please enter mobile number"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container-fluid bg-black">
        <div className="container pb-5 atservice">
          <div className="rowatservice">
            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-2 text-white hfs48 pt-5 animated slideInDown">
                Try it out
              </h1>
            </div>
          </div>

          <div className="row pt-3" style={{ paddingLeft: "0px" }}>
            <div className="col-lg-1 col-md-12"></div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded p-4">
                  <p className="pfss24 mb-0">Buy a Merge</p>
                  <p className="mb-4 pfs16-white">Free for 3 months</p>
                  <p className="mb-4 pfs16-white">
                    Get 3 months of free usage of Dreamspace with a purchase of
                    Augrade Merge
                  </p>
                  <div className="text-left pt-2" bis_skin_checked="1">
                    <button type="button" className="btn btn-white">
                      Buy now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded p-4">
                  <p className="pfss24 mb-0">Try For free </p>
                  <p className="mb-4 pfs16-white">Limited features</p>
                  <p className="mb-4 pfs16-white">
                    Experience the power of Dreamsapce for free & decide if it’s
                    the right tool for you
                  </p>
                  <div className="text-left pt-2" bis_skin_checked="1">
                    <button type="button" className="btn btn-white try-btn ">
                      Try for free<i className="bi bi-chevron-right ms-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded p-4">
                  <p className="pfss24 mb-0">Full power</p>
                  <p className="mb-4 pfs16-white">Monthly or yearly plans</p>
                  <p className="mb-4 pfs16-white">
                    Love Dreamspace? We are here
                    <br /> to support your workflow,
                    <br /> end-to-end
                  </p>
                  <div className="text-left pt-2" bis_skin_checked="1">
                    <button type="button" className="btn btn-white">
                      Buy now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
            ></div>
          </div>
        </div>
      </div> */}

      
    </div>
  );
}
