import React from 'react'

function CommonQuestions() {
    return (
        <>
            <div
                className="container-fluid mb-5 pb-5 contactus-accordin"
                bis_skin_checked="1"
            >
                <div className="container" bis_skin_checked="1">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h1 className="hfs40 mb-5">Some common questions</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row" bis_skin_checked="1">
                        <div className="col-md-3" bis_skin_checked="1">
                            <div
                                className="nav flex-column nav-pills nav-pills-custom contact"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                                bis_skin_checked="1"
                            >
                                <a
                                    className="nav-link mb-3 p-3 text-center active"
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    href="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    <span className="pfs20">Augrade Company</span>
                                </a>

                                {/*  <a
                  className="nav-link mb-3 p-3 text-center"
                  id="v-pills-profile-tab"
                  data-toggle="pill"
                  href="#v-pills-profile"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  <span className="pfs20">Our Products</span>
                </a>

                <a
                  className="nav-link mb-3 text-center p-3"
                  id="v-pills-messages-tab"
                  data-toggle="pill"
                  href="#v-pills-messages"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  <span className="pfs20">Your Account</span>
                </a> */}
                            </div>
                        </div>

                        <div className="col-md-1" bis_skin_checked="1"></div>

                        <div className="col-md-8" bis_skin_checked="1">
                            <div
                                className="tab-content"
                                id="v-pills-tabContent"
                                bis_skin_checked="1"
                            >
                                <div
                                    className="tab-pane fade bg-white show active"
                                    id="v-pills-home"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-home-tab"
                                    bis_skin_checked="1"
                                >
                                    <div
                                        className="accordion"
                                        id="accordionExample"
                                        bis_skin_checked="1"
                                    >
                                        <div className="accordion-item" bis_skin_checked="1">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className="accordion-button pfs20roboto collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                >
                                                    What is Augrade?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample"
                                                bis_skin_checked="1"
                                            >
                                                <div
                                                    className="accordion-body pfs16-black"
                                                    bis_skin_checked="1"
                                                >
                                                    We are on a mission to make AR/VR accessible &
                                                    adaptable to how we live, work & play. To realise this
                                                    mission we are starting by creating AR glasses &
                                                    empowering 1.5 lakh+ architects to think, work &
                                                    present their in immersive 3D via a B2B sales route.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item" bis_skin_checked="1">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                    className="accordion-button pfs20roboto collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo"
                                                >
                                                    How can I get updates about your products?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample"
                                                bis_skin_checked="1"
                                            >
                                                <div
                                                    className="accordion-body pfs16-black"
                                                    bis_skin_checked="1"
                                                >
                                                    Please write a mail to us at augrade0@gmail.com and
                                                    we’ll add you to our mail list.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item" bis_skin_checked="1">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className="accordion-button pfs20roboto collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="collapseThree"
                                                >
                                                    How can I join Augrade?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingThree"
                                                data-bs-parent="#accordionExample"
                                                bis_skin_checked="1"
                                            >
                                                <div
                                                    className="accordion-body pfs16-black"
                                                    bis_skin_checked="1"
                                                >
                                                    Please check out our careers page for more information
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Are your products available?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          "Not yet, we are testing and working with architects
                          to develop the best possible solution."
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Can I join the Early access program?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Yes, please email us augrade0@gmail.com or connect with us using contact form.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          When will the product(s) launch?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How can I avail your Website/app development service?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div> */}
                                    </div>
                                </div>

                                {/* <div
                  className="tab-pane fade bg-white"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                  bis_skin_checked="1"
                  >
                  <div
                    className="accordion"
                    id="accordionExample"
                    bis_skin_checked="1"
                  >
                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is Augrade?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How can I get updates about your products?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How can I join Augrade?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Are your products available?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Can I join the Early access program?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          When will the product(s) launch?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How can I avail your Website/app development service?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
 */}
                                {/* <div
                  className="tab-pane fade bg-white"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                  bis_skin_checked="1"
                 >
                  <div
                    className="accordion"
                    id="accordionExample"
                    bis_skin_checked="1"
                  >
                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is Augrade?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How can I get updates about your products?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How can I join Augrade?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Are your products available?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Can I join the Early access program?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          When will the product(s) launch?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" bis_skin_checked="1">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button pfs20roboto collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How can I avail your Website/app development service?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        bis_skin_checked="1"
                      >
                        <div
                          className="accordion-body pfs16-black"
                          bis_skin_checked="1"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommonQuestions
