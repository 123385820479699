import React from 'react'

function Powerful() {
  return (
    <>
      <div className="container-fluid py-5">
        <div className="container howit">
          <div className="row">
            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-5 mb-3 hfs54black">
                Powerful inside
                <br /> Beautiful outside
              </h1>
              <p className="pfsg24 mb-5">
                A powerful & easy-to-use wearable computer
                <br /> designed to enhance how you live, work & play
              </p>
            </div>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-12 col-md-12 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <img
                src="img/powerful-inside.png"
                className="img-fluid rounded-top"
                alt="augrade"
                style={{ marginTop: "-60px", width: "60%" }}
              />
            </div>
          </div>
          <div className="text-center">
            <button type="button" className="btn btn-blue mt-3">
              Take a closer look
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Powerful
