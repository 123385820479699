import React from 'react'
import { Link } from 'react-router-dom'
import BuyNow from '../ButtonComponent/BuyNow';

function AugradeMerge() {
  var btnclassText="btn btn-black";
  return (
    <>
      <div className="container-xxl py-5 pt-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "650px" }}
          >
            <h1 className="display-5 hfs48black">Augrade Merge</h1>
            <p className="fw-normal pfs20roboto">
              The Lightest yet most powerful
              <br />
              Spatial Computer
            </p>

            <div className="text-center mt-4 mb-5 pt-2">
              <Link to="/MergePricing">
                <BuyNow btnclassText={btnclassText}/>
              </Link>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-12 col-md-12 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <img
                src="img/merge.png"
                className="img-fluid rounded-top"
                alt="augrade"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AugradeMerge
