import React from 'react'

function WatchDemoVideo(props) {
    const { watchdemoclassText } = props;
    return (
        <>
            <button
                type="button"
                className={watchdemoclassText}>
                Demo video
            </button>
        </>
    )
}

export default WatchDemoVideo
