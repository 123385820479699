import React from 'react'
import { Link } from 'react-router-dom'
import KnowMore from '../ButtonComponent/KnowMore'

function AiSupported() {
  return ( 
    <>
      <div className="container-fluid aisupported py-5 mt-4">
        <div className="container">
          <div className="atservice">
            <div
              className="mx-auto mb-5 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ paddingLeft: "12px" }}
            >
              <h1 className="display-5 mb-4 hfs48roboto">
                AI generated technically feasible & accurate
                <br /> CAD models
              </h1>
              <p className="fs-5 fw-medium text-primary">
                Our AI understands your intention, needs & constraints to help
                you design better & faster
              </p>
            </div>
            <div className="row g-4">
              <div
                className="col-lg-4 col-md-12 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item rounded overflow-hidden pb-4">
                  <img
                    className="dream img-fluid"
                    src="img/dreamspace-new2.png"
                    alt="augrade"
                  />

                  <div className="team-content pt-3 pb-3">
                    <div className="row">
                      <div className="col-lg-9 px-5">
                        <Link to="/RealEstate">
                          <h5 className="pfs24black">AEC</h5>
                        </Link>
                      </div>
                      <div className="col-lg-3">
                        <Link to="/RealEstate">
                          <img
                            className="img-fluid"
                            src="img/Vector.png"
                            alt="augrade"
                            style={{ width: "32px", height: "32px" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-12 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item rounded overflow-hidden pb-4">
                  <img
                    className=" dream img-fluid"
                    src="img/dreamspace-new3.png"
                    alt="augrade"
                  />

                  <div className="team-content pt-3 pb-3">
                    <div className="row">
                      <div className="col-lg-9 px-5">
                        <Link to="/Aerospace">
                          <h5 className="pfs24black">Space Tech</h5>
                        </Link>
                      </div>
                      <div className="col-lg-3">
                        <Link to="/Aerospace">
                          <img
                            className="img-fluid"
                            src="img/Vector.png"
                            alt="augrade"
                            style={{ width: "32px", height: "32px" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-12 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item rounded overflow-hidden pb-4">
                  <img
                    className="dream img-fluid"
                    src="img/dreamspace-new4.png"
                    alt="augrade"
                  />

                  <div className="team-content pt-3 pb-3">
                    <div className="row">
                      <div className="col-lg-9 px-5">
                        <Link to="/Automotive">
                          <h5 className="pfs24black">Automotive</h5>
                        </Link>
                      </div>
                      <div className="col-lg-3">
                        <Link to="/Automotive">
                          <img
                            className="img-fluid"
                            src="img/Vector.png"
                            alt="augrade"
                            style={{ width: "32px", height: "32px" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-center">
              <button type="button" className="btn btn-blue mt-3">
                Know more
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default AiSupported



