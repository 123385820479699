import React from 'react'
import { Link } from 'react-router-dom'
import LearnMore from '../ButtonComponent/LearnMore';

function Automobile() {
    var learnclassText="btn btn-blue mt-3";
  return (
    <>
        <div className="mobilefullbanner">
        <div
          className="container automotive-banner py-5 mt-5 mb-5 wow fadeIn rounded-40"
          data-wow-delay="0.1s"
        >
          <div className="container text-center pt-5 pb-5 py-5">
            <h1 className="display-2 text-white mt-5 mb-5 hfs48 pt-5 pb-5 animated slideInDown">
              Automobile
            </h1>
          </div>
        </div>

        <div className="container vertical-align-top">
          <div className="row">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-5 mb-4 hfs36roboto merge-industry">
                Design the future of
                <br /> Mobility, with ease
              </h1>
            </div>
            <div
              className="col-lg-7 wow fadeInUp merge-power"
              data-wow-delay="0.5s"
            >
              <p className="mb-4 fw-normal pfs24black">
                Merge powered by Merge OS, can learn about any car’s mechanical,
                aerodynamic & such needs just with a 3D model as input &
                empowers you to make the relevant edits as you see fit,
                eliminating human-errors in the design, simulation &
                demonstration processes.
              </p>
              <Link to="/Automotive">
                <LearnMore learnclassText={learnclassText}/>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Automobile
