import React from 'react'
import Apply from '../ButtonComponent/Apply'
import Info from '../ButtonComponent/Info';

function OpenPositionsMobile() {
  var applyclassText="btn btn-black";
  var infoclassText="btn btn-black";
  return ( 
    <>
      <div className="mobiletable">
            <div className="container mt-5 ">
                <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
                    <h1 className="display-5 mb-5 hfs32roboto">Open Positions</h1>
                </div>
                
                <div className="table-wrap">
                    <div class="row ">
                      <div class="col-lg-16 col-8">
                        <p className="pfs28roboto">GNN Engineer</p>
                        </div>
                        <div className="col-4">
                        <a href="https://forms.gle/EvjvXR7GfL3nMKACA" target="_blank" >
                          <Apply applyclassText={applyclassText}/>
                           </a>
                         </div>
                    </div>
                    <div class="row ">
                      <div class="col-lg-16 col-8">
                        <p className="pfs20">GNN Team </p>
                        </div>
                        <div className="col-4">
                        <a href="https://augrade.notion.site/Join-Augrade-3bea0c252bcc467da51edba3a1646a3c" target="_blank">
                          <Info infoclassText={infoclassText}/>
                           </a>
                         </div>
                    </div>
                    
                    <div><p className="pfs20">Remote</p></div>
                    <br/>
                    <br/>

                    <div class="row ">
                      <div class="col-lg-16 col-8">
                        <p className="pfs28roboto">3D/CAD Engineer</p>
                        </div>
                        <div className="col-4">
                        <a href="https://forms.gle/EvjvXR7GfL3nMKACA" target="_blank" >
                          <Apply applyclassText={applyclassText}/>
                           </a>
                         </div>
                    </div>
                    <div class="row ">
                      <div class="col-lg-16 col-8">
                        <p className="pfs20">CAD/Geometric Engine Team </p>
                        </div>
                        <div className="col-4">
                        <a href="https://augrade.notion.site/Join-Augrade-3bea0c252bcc467da51edba3a1646a3c" target="_blank">
                          <Info infoclassText={infoclassText}/>
                           </a>
                         </div>
                    </div>
                    <div><p className="pfs20">Hybrid (Bangalore or San Francisco)</p></div>
                    <br/>
                    <br/>

                    <div class="row ">
                      <div class="col-lg-16 col-8">
                        <p className="pfs28roboto">Java/C++ Developer</p>
                        </div>
                        <div className="col-4">
                        <a href="https://forms.gle/EvjvXR7GfL3nMKACA" target="_blank" >
                          <Apply applyclassText={applyclassText}/>
                           </a>
                         </div>
                    </div>
                    <div class="row ">
                      <div class="col-lg-16 col-8">
                        <p className="pfs20">Desktop application team </p>
                        </div>
                        <div className="col-4">
                        <a href="https://augrade.notion.site/Join-Augrade-3bea0c252bcc467da51edba3a1646a3c" target="_blank">
                          <Info infoclassText={infoclassText}/>
                           </a>
                         </div>
                    </div>
                    <div><p className="pfs20">Hybrid (Bangalore or San Francisco)</p></div>
                    <br/>
                    <br/>
                    
                    
                    
                </div>
            </div>
        </div>
    </>
  )
}

export default OpenPositionsMobile
