import React from 'react'
import { Link } from 'react-router-dom'
import AtServiceReadMore from '../ButtonComponent/AtServiceReadMore';

function AtYourService() {
    var readmoreclassName="bi bi-chevron-right ms-2";
  return (
    <>
      <div className="container-fluid bg-black">
        <div className="container pb-5 atservice">
          <div className="rowatservice">
            <div
              className="col-md-12 col-lg-12 mx-auto wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <h1 className="display-2 text-white hfs48 pt-5 animated slideInDown">
                At your service,
                <br /> Every step of the way
              </h1>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-lg-1 col-md-1"></div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded p-4">
                  <p className="pfs32 mb-3">
                    Easy
                    <br /> replacement
                  </p>
                  <p className="mb-4 pfss18-white">
                    Device damaged? We
                    <br /> offer quick & easy
                    <br /> replacement, T&C apply
                  </p>
                  <Link to="" className="color-blue-light">
                    <AtServiceReadMore readmoreclassName={readmoreclassName}/>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded p-4">
                  <p className="pfs32 mb-3">Free repair & maintenance</p>
                  <p className="mb-4 pfss18-white">
                    We help you repair &<br /> maintain the device for
                    <br /> 2 yrs
                  </p>
                  <Link to="" className="color-blue-light">
                    <AtServiceReadMore readmoreclassName={readmoreclassName}/>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded p-4">
                  <p className="pfs32 mb-3">3 months free service</p>
                  <p className="mb-4 pfss18-white">
                    Get free 3 months of
                    <br /> software once you buy
                    <br /> our device
                  </p>
                  <Link to="" className="color-blue-light">
                    <AtServiceReadMore readmoreclassName={readmoreclassName}/>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
            ></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AtYourService
