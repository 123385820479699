import React from 'react'
import Header from './Header'
import BlogPage1 from './BlogPageComponents/BlogPage1'
import Summary from './BlogPageComponents/Summary'
import Section1 from './BlogPageComponents/Section1'

export default function BlogPage() {
  return (
    <div>
        <Header/>

        <BlogPage1/>

        <Summary/>
        
        <Section1/>
        
        
    </div>
  )
}
