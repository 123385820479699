import React from 'react'

function CantFind() {
    return (
        <>
            <div className="container-fluid bg-gray py-5" bis_skin_checked="1">
                <div className="container" bis_skin_checked="1">
                    <div
                        className="mx-auto text-center wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <h1 className="display-5 mb-4 hfs40roboto">
                            Can't find what you are looking for?
                        </h1>
                        <p className="pfs20">
                            If you still can't find what you're looking for in our support
                            resources, contact customer support directly.
                        </p>
                        <br />
                        <button
                            type="button"
                            className="btn btn-black"
                            onClick={() =>
                                (window.location.href = "https://forms.gle/cSp2GHjgHdQMVRLD8")
                            }
                        >
                            CONTACT US
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CantFind
