import React from 'react'

function MergeOs() {
  return (
    <>
      <section id="tabs" className="project-tab pb-5">
        <div className="container-xxl py-5">
          <div className="container-fluid">
            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
              <h3 className="pfs40gothicblack merge-text">Merge OS</h3>
              <p className="pfs16 merge-text">
                Everything that makes Merge worth your style. Be the best
                version of you, always!{" "}
              </p>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="image-border mt-4 mb-5">
                      <img
                        src="img/merge_os.png"
                        className="img-fluid"
                        alt="augrade"
                      />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div className="image-border mt-4 mb-5">
                      <img
                        src="img/merge_os.png"
                        className="img-fluid"
                        alt="augrade"
                      />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    <div className="image-border mt-4 mb-5">
                      <img
                        src="img/merge_os.png"
                        className="img-fluid"
                        alt="augrade"
                      />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-service-item4"
                    role="tabpanel"
                    aria-labelledby="nav-service-item4"
                  >
                    <div className="image-border mt-4 mb-5">
                      <img
                        src="img/merge_os.png"
                        className="img-fluid"
                        alt="augrade"
                      />
                    </div>
                  </div>
                </div>
                <nav>
                  <div
                    className="nav nav-tabs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link col-lg-3 col-md-3 active"
                      id="nav-home-tab"
                      data-toggle="tab"
                      href="#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      <div className="service-item position-relative h-100">
                        <div className="rounded p-4">
                          <h5 className="mb-3">Point to access information</h5>
                          <p className="mb-0">
                            From information to entertainment, everything with a
                            point
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      className="nav-item nav-link col-lg-3 col-md-3"
                      id="nav-profile-tab"
                      data-toggle="tab"
                      href="#nav-profile"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      <div className="service-item position-relative h-100">
                        <div className="rounded p-4">
                          <h5 className="mb-3">Multiple Screens</h5>
                          <p className="mb-0">
                            Work anytime, anywhere with multiple re-sizable
                            screens
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      className="nav-item nav-link col-lg-3 col-md-3"
                      id="nav-contact-tab"
                      data-toggle="tab"
                      href="#nav-contact"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      <div className="service-item position-relative h-100">
                        <div className="rounded p-4">
                          <h5 className="mb-3">Understands depth</h5>
                          <p className="mb-0">
                            Our OS measures how far an object is & changes the
                            interaction mode
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      className="nav-item nav-link col-lg-3 col-md-3"
                      id="nav-service-item4"
                      data-toggle="tab"
                      href="#nav-service-item4"
                      role="tab"
                      aria-controls="nav-service-item4"
                      aria-selected="false"
                    >
                      <div className="service-item position-relative h-100">
                        <div className="rounded p-4">
                          <h5 className="mb-3">
                            Compatible with existing devices
                          </h5>
                          <p className="mb-0">
                            Merge can exchange information with your mobile,
                            laptop & PC
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MergeOs
