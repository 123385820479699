import React from 'react'

function Gen1() {
  return (
    <>
      <div className="container-fluid bg-gray">
        <div className="container vertical-align-middle banner-first">
          <div className="row pt-4 pb-4">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p className="pfs20">Augrade Dreamspace</p>
              <h1 className="display-5 mb-4">Gen 1</h1>
              <p className="pfs20">
                Create manufacturing/construction grade CAD with text/image/speech as input
              </p>

              <br />
              <button
                type="button"
                className="btn btn-dark"
                onClick={() =>
                  (window.location.href = "https://forms.gle/cSp2GHjgHdQMVRLD8")
                }
              >
                Try for free<i className="bi bi-chevron-right ms-2"></i>
              </button>
            </div>

            <div
              className="col-lg-6 mt-5 mb-5 pt-5 pb-5 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <img
                className="img-fluid  "
                src="img/dreamspace.png"
                alt="augrade"
                style={{ border: "2px  ", borderRadius: "20px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Gen1
