import React from 'react'

function Workingdesktop() {
    return (
        <> 
            <div className="desktoptable">
                <div className="container mt-5 pt-4">
                    {/* <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s"> 
                        <h1 className="display-5 mb-4 hfs40roboto">How it works</h1>
                    </div>*/}
                    <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h2 className="hfs40 mb-5">How it works?</h2>
          </div>

                    <div className="table-wrap">
                        <table className="table table-responsive table-borderless">
                            <tbody>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                         
                                    </td>
                                    <td>
                                    <div className="pfs28roboto">AI Powered CAD Generations</div>
                                    <p classname="pfs20">Describe & Upload</p>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                                Describe your requirements/specifications & Upload all relevant files which contain more details on your outcome expected.
                                                The model is built within seconds/minutes.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                         
                                    </td>
                                    <td>
                                    <div className="pfs28roboto">Editing & Simulations</div>
                                    <p classname="pfs20">Edit, Analyse & Customise</p>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                            Using Augrade's AI powered Geometric, Physics & Simulation engines with parametric capabilities anyone can ensure your design is technically feasible & compliant with all regulations. 
                                                
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                        
                                    </td>
                                    <td>
                                        <div className="pfs28roboto">Collaborate Effectively</div>
                                        <p classname="pfs20">Cross-tool & Multi-team</p>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                            We enable multiple teams to interact & use design files across tools in real time.               
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Workingdesktop

