import React from 'react'
import { Link } from 'react-router-dom'

function TrustedPeople() {
  return (
    <>
      <section className="logoMarqueeSection">
        <div className="container" id="logoMarqueeSection">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h2 className="hfs40 mb-5">Supported by people across Industries</h2>
          </div>
          <div className="container-fluid aisupported mt-5">
                <div className="container">
                    <div className="atservice">
                        <div className="mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h1
                                className="display-5 mb-4 hfs40roboto"
                                style={{ marginLeft: "12px" }}
                            >
                                Partners
                            </h1>
                        </div>
                        <div className="row g-4">
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.1s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    {/* <img className="img-fluid" src="img/partner1.png" alt="augrade"/> */}
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.3s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    <img
                                        className="img-fluid"
                                        src="img/partner1.png"
                                        alt="augrade"
                                    />
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.5s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    <img
                                        className="img-fluid"
                                        src="img/partner2.png"
                                        alt="augrade"
                                    />
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.7s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    {/* <img className="img-fluid" src="img/dreamspace1.png" alt="augrade"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <div className="container-fluid aisupported mt-5 mb-5">
                <div className="container">
                    <div className="atservice">
                        <div className="mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h1
                                className="display-5 mb-4 hfs40roboto"
                                style={{ marginLeft: "12px" }}
                            >
                                Investors
                            </h1>
                        </div>
                        <div className="row g-4">
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.1s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    {/* <img className="img-fluid" src="img/partner1.png" alt="augrade"/> */}
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.3s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    <img
                                        className="img-fluid"
                                        src="img/investor1.png"
                                        alt="augrade"
                                    />
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.5s"
                            >
                                <div className="team-item rounded overflow-hidden pt-5 pb-4">
                                    <img
                                        className="img-fluid"
                                        src="img/investor2.png"
                                        alt="augrade"
                                    />
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.7s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    {/* <img className="img-fluid" src="img/dreamspace1.png" alt="augrade"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          {/*<div className="default-content-container flex items-center">
            <div
              className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block"
              style={{ margin: "0px 68px" }}
            >
              <div className="mb-4" style={{ animationDuration: "57s" }}>
                <div className="row">
                  <div className="col-md-3">
                    {/* <Link><img src="img/partner1.png" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link> }
                  </div>
                  <div className="col-md-3">
                    <Link>
                      <img
                        src="img/partner1.png"
                        title=""
                        className="marqueelogo"
                        alt="augrade"
                        style={{ width: "auto", maxWidth: "none" }}
                      />
                    </Link>
                  </div>
                  <div className="col-md-3">
                    <Link>
                      <img
                        src="img/partner2.png"
                        title=""
                        className="marqueelogo"
                        alt="augrade"
                        style={{ width: "auto", maxWidth: "none" }}
                      />
                    </Link>
                  </div>
                  
                 
                  <div className="col-md-4">
                    <Link>
                      <img
                        src="img/investor1.png"
                        title=""
                        className="marqueelogo"
                        alt="augrade"
                        style={{ width: "auto", maxWidth: "none" }}
                      />
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link>
                      <img
                        src="img/investor2.png"
                        title=""
                        className="marqueelogo"
                        alt="augrade"
                        style={{ width: "auto", maxWidth: "none" }}
                      />
                    </Link>
                  </div>
                </div>

                {/* <Link><img src="img/partner1.png" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/partner2.png" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/partner1.png" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link> }
              </div>
              {/* <div className="marquee" style={{animationDuration: "57s"}}>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                        <Link><img src="img/client.jpg" title="" className="marqueelogo" alt="augrade" style={{width: "auto" , maxWidth:"none"}} /></Link>
                    </div> }
            </div>
          </div> */}
        </div>
      </section>
    </>
  )
}

export default TrustedPeople
