import React, { useState,useCallback } from 'react'
import Header from './Header';
import Welcome from './ContactUsComponents/Welcome';
import CommonQuestions from './ContactUsComponents/CommonQuestions';
import CantFind from './ContactUsComponents/CantFind';


export default function ContactUs() {

    const FORM_ENDPOINT =
  "https://practical-roentgen.31-187-70-64.plesk.page/api/Api.php";

    const [first_name,setFirstname]=useState("");
    const [last_name,setLastname]=useState("");
    const [email_id,setEmailid]=useState("");
    const [select_interest,setSelectinterest]=useState("")
    
    const [arGlasses, setArGlasses] = useState(false)
    const [companyUpdates, setCompanyUpdates] = useState(false)
    const [ar, setAr] = useState(false)
    const [wearable, setWearable] = useState(false)
    
    const handleArGlasses=()=>{
        setArGlasses(!arGlasses);
    }
    const handleCompanyUpdates=()=>{
        setCompanyUpdates(!companyUpdates);
    }
    const handleAr=()=>{
        setAr(!ar);
    }
    const handleWearable=()=>{
        setWearable(!wearable);
    }

    
        const data = {
            first_name: first_name,
            last_name: last_name,
            email_id : email_id,
            ar_glasses:arGlasses,
            company_updates:companyUpdates,
            ar_vr:ar,
            wearable:wearable
          };

        //   const onSubmitHandler = useCallback(() => {
        //     fetch(FORM_ENDPOINT, {
        //       method: "POST",
        //       headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //       },
        //       body: JSON.stringify(data),
        //     })
        //       .then((response) => {
        //         if (response.status !== 200) {
        //           throw new Error(response.statusText);
        //         }
        //         return response.json();
        //       })
        //       .then(() => {
        //         console.log("We'll be in Touch with you.");
        //         console.log("success");
        //       })
        //       .catch((err) => {
        //         console.log(err.toString());
        //         console.log("error");
        //       });
        //   }, []);setCheckboxValues
          

          function saveUser(e){
            // alert(data.interest);
            e.preventDefault();
            // let data={first_name,last_name,email_id,ar_glasses}
    
            fetch("https://practical-roentgen.31-187-70-64.plesk.page/api/Api.php", {
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(data)
            }).then((result)=>{
                // alert("result"+result);
                // window.location.href='/ContactUs';
            }).then((responseJson) => {
                if (responseJson.status === 200 && responseJson.message === "Mail sent") {
                    // Redirect to the ContactUs page
                    // window.location.href = '/ContactUs';
                } else {
                    // Handle other cases if needed
                    console.error("API Error:", responseJson.message);
                }
            }).catch((error) => {
                // Handle network or other errors here
                console.error("Error:", error);
            });
    
            // fetch('https://practical-roentgen.31-187-70-64.plesk.page/api/Api.php', {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   body: JSON.stringify(data),
            // }) 
            //   .then((response) => {
            //     if (response.ok) {
            //       window.location.reload();
            //     } else {
            //       console.error('API Error:', response.statusText);
            //     }
            //   })
            //   .catch((error) => {
            //     // Handle network or other errors here
            //     console.error('Error:', error);
            //   });
            
            
        }
    

    // function saveUser(){
    //     alert(first_name);
    //     let data={first_name,last_name,email_id}

    //     fetch("https://practical-roentgen.31-187-70-64.plesk.page/api/Api.php", {
    //         method:'POST',
    //         headers:{
    //             'Accept':'application/json',
    //             'Content-Type':'application/json'
    //         },
    //         body:JSON.stringify(data)
    //     }).then((result)=>{
    //         alert("result"+result);
    //     })
    // }

  return (
    <div>
      <Header />

      <Welcome/>

      <CommonQuestions/>

      <CantFind/>
      

    </div>
  );
}
