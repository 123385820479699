import React from 'react'

function RightPanel(props) {
    // var{increment, decrement, count} = props;
    const {renderStep} = props;
  return (
    <>
        <div className="col-lg-4 order-3 px-4">
                                <div className="product_description">
                                    <div className="hfs40roboto mb-4">Augrade Dreamspace</div>

                                    <div> <span className="product_price">$599</span> &nbsp;&nbsp;&nbsp;<button type="button" className="btn btn-dark">Starting price</button></div>
                                    <p className="pt-4 mb-4 pfs20roboto">Create, Edit/Validate & Visualise 3D models, on the go.</p>

                                    <hr className="singleline" />


                                    <div className="wow fadeInUp" data-wow-delay="0.1s">

                        {renderStep()}

                    </div>



                    <br /><br />


                                    {/* <p className="pfs28roboto">Select Industry specific workspace</p>

                                    <a href="#divShowHide"><div id="btnShowHideReal" className="row product-realestate text-center mt-4">
                                        <p className="pfs24">Real estate</p>
                                    </div></a>

                                    <a href="#divShowHide"><div id="btnShowHideAerospace" className="row product-aerospace text-center mt-4">
                                        <p className="pfs24">Aerospace</p>
                                    </div></a>

                                    <a href="#divShowHide"><div id="btnShowHideAutomobile" className="row product-automobile text-center mt-4">
                                        <p className="pfs24">Automobile</p>
                                    </div></a>

                                    <hr className="singleline mt-4" />

                                    <div id="divShowHide">
                                        <div className="row">

                                            <div className="col-lg-12 vertical-align-middle">
                                                <div className="mt-5 text-center">
                                                    <p className="pfs28roboto">Which plan do you want <br /> <span className="pfs16-black">( You can change later )</span></p>
                                                </div>
                                                <div className="row mt-4 pt-4 px-4" style={{ border: "1px solid #000", borderRadius: "40px" }}>
                                                    <div className="col-lg-6">
                                                        <p className="pfs28roboto">Starter Plan</p>
                                                    </div>
                                                    <div className="col-lg-6" style={{ textAlign: "right" }}>
                                                        <p className="pfs28roboto">$599 <br /> <span className="pfs16-black">/yr/user</span></p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">

                                            <div className="col-lg-12 vertical-align-middle">
                                                <div className="row mt-4 pt-4 px-4" style={{ border: "1px solid #000", borderRadius: "40px" }}>
                                                    <div className="col-lg-6">
                                                        <p className="pfs28roboto">Pro Plan</p>
                                                    </div>
                                                    <div className="col-lg-6" style={{ textAlign: "right" }}>
                                                        <p className="pfs28roboto">$1249 <br /> <span className="pfs16-black">/yr/user</span></p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="row mt-4 pt-4" style={{ border: "1px solid #000", borderRadius: "40px" }}>
                                        <p className="pfs20roboto">Add a coverage plan</p>
                                        <p className="pfs16-black">$499/yr</p>
                                        <div className="col-lg-4">
                                            <img src="img/merge.png" className="img-fluid rounded-top" alt="augrade" />
                                        </div>
                                        <div className="col-lg-8">

                                            <p className="pfs16-black">Your seamless usage & peace of mind is precious. Get accidental damage protection & coverage for your new Merge.</p>
                                            <ul>
                                                <li className="pfs16-black">Accidental damage protection</li>
                                                <li className="pfs16-black">Theft & Loss coverage</li>
                                                <li className="pfs16-black">Priority support & care</li>
                                                <li className="pfs16-black">Express shipping</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row mt-4 p-3" style={{ border: "1px solid #000", borderRadius: "40px" }}>

                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="gridCheck1" />
                                            <label className="form-check-label pfs20roboto" for="gridCheck1">I don’t want the industry specific software now</label>
                                        </div>
                                    </div>


                                    <div className="row mt-5 mb-5 text-center">

                                        <div className="col-xs-6"> <button type="button" className="btn btn-dark btn100width">Proceed to checkout</button>

                                        </div>
                                    </div> */}


                                </div>
                            </div>
    </>
  )
}

export default RightPanel
