import React from "react";
import { Link } from "react-router-dom";

function HeaderSticky() {
  return (
    <>
      <div
        id="desktop-header"
        className="container-fluid bg-primary headersticky"
        style={{ display: "grid" }}
      >
        <div className="container">
          <nav
            className="navbar navbar-expand-lg bg-primary navbar-light p-lg-0"
            style={{ margin: "0px 80px" }}
          >
            <div className="col-md-3">
              <Link to="/" className="navbar-brand">
                <img src="img/augrade_logo.png" alt="augrade logo" />
              </Link>
            </div>
            <div className="col-md-9">
              <button
                type="button"
                className="navbar-toggler me-0"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav">
                  {/* <Link to="/Merge" className="nav-item nav-link active">Merge</Link> */}
                  {/* <Link }
                    to=""
                    className="nav-item nav-link active"
                    data-toggle="modal"
                    data-target="#exampleModalcoming"
                  >
                    Merge
                  </Link>*/}
                  <Link to="/Dreamspace" className="nav-item nav-link active">
                    Dreamspace
                  </Link>
                  <div className="nav-item dropdown">
                    <Link
                      to=""
                      className="nav-link dropdown-toggle active"
                      data-bs-toggle="dropdown"
                    >
                      Use Cases 
                    </Link>
                    <div className="dropdown-menu bg-light rounded-0 rounded-bottom m-0">
                      <Link className="dropdown-item" to="/RealEstate">
                        AEC
                      </Link>
                      <Link className="dropdown-item" to="/Aerospace">
                        Aerospace
                      </Link>
                      <Link className="dropdown-item" to="/Automotive">
                        Automotive
                      </Link>
                    </div>
                  </div>
                  <Link to="/OurStory" className="nav-item nav-link active">
                    About us
                  </Link>
                  <Link to="/Career" className="nav-item nav-link active">
                    Career
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>

        <div
          className="ms-auto d-none d-lg-block"
          style={{ marginTop: "-62px" }}
        >
          <a
            href="https://forms.gle/cSp2GHjgHdQMVRLD8"
            target="_blank"
            className="btn btn-primary rounded-pill py-1"
          >
            Waitlist
          </a>
        </div>
      </div>

      {/* Mobile Header */}
      <div className="mobile-header container-fluid bg-primary sticky-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg bg-primary navbar-light p-lg-0">
            <a href="/" className="navbar-brand">
              <img src="img/augrade_logo.png" alt="" />
            </a>
            <button
              type="button"
              className="navbar-toggler me-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav" style={{ marginBottom: "15px" }}>
                {/* <Link to="/Merge" className="nav-item nav-link active">Merge</Link> */}
                {/* <Link }
                  to=""
                  className="nav-item nav-link active"
                  data-toggle="modal"
                  data-target="#exampleModalcoming"
                >
                  Merge
                </Link>*/}
                <Link to="/Dreamspace" className="nav-item nav-link active">
                  Dreamspace
                </Link>
                <div className="nav-item dropdown">
                  <Link
                    to="/MergePricing"
                    className="nav-link dropdown-toggle active"
                    data-bs-toggle="dropdown"
                  >
                    Use Cases
                  </Link>
                  <div className="dropdown-menu bg-light rounded m-0">
                    <Link className="dropdown-item" to="/RealEstate">
                      AEC
                    </Link>
                    <Link className="dropdown-item" to="/Aerospace">
                      Aerospace
                    </Link>
                    <Link className="dropdown-item" to="/Automotive">
                      Automotive
                    </Link>
                  </div>
                </div>
                <Link to="/OurStory" className="nav-item nav-link active">
                  About us
                </Link>
                <Link to="/Career" className="nav-item nav-link active">
                  Career
                </Link>
                <Link
                  to="https://forms.gle/cSp2GHjgHdQMVRLD8"
                  className="nav-item nav-link active"
                >
                  Free trial
                </Link>
              </div>
              {/* <div className="ms-auto d-none d-lg-block">
                        <a href="" className="btn btn-primary rounded-pill py-2 px-3">Get A Quote</a>
                    </div> */}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default HeaderSticky
