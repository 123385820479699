import React from 'react'

function Summary() {
  return (
    <>
        <div className="container py-5 mt-4">
            <div className="row">
                <div className="col-lg-4">
                    <h1 className="display-5 mb-4 hfs40-bloggray">Summary</h1>
                </div>
                <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
                    <p className="mb-4 fw-normal pfs24gothic-bloggray">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                    <p className="mb-4 fw-normal pfs24gothic-bloggray">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                </div>
            </div>
        </div>
        
        
        <div className="container-xxl" bis_skin_checked="1">
            <div className="container">
                <img src="img/blogpage2.png" className="img-fluid" alt="augrade"/>
            </div>
        </div>
    </>
  )
}

export default Summary
