import React from 'react'

function RightPanel(props) {
    var{increment, decrement, count} = props;
    const {renderStep} = props;
    return (
        <>
            <div className="col-lg-4 order-3 px-4">
                <div className="product_description">
                    <div className="hfs40roboto mb-4">Augrade Merge</div>

                    <div> <span className="product_price">$1249</span> &nbsp;&nbsp;&nbsp;<button type="button" className="btn btn-dark">Early bird price</button></div>
                    <p className="pt-4 mb-4 pfs20roboto">The Lightest yet most powerful Spatial Computer</p>
                    <div className="row">
                        <div className="col-lg-8">
                            <p className="pfs24gothic-black">Number of devices</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="product_quantity">

                                <label>{count}</label>
                                <div className="quantity_buttons">

                                    <div id="quantity_inc_button" onClick={increment} className="quantity_inc quantity_control"><i className="fas fa-chevron-up"></i></div>
                                    <div id="quantity_dec_button" onClick={decrement} className="quantity_dec quantity_control"><i className="fas fa-chevron-down"></i></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr className="singleline" />
                    <div>
                        <div className="row" style={{ marginTop: "15px" }}>
                            <div className="col-xs-6"> <span className="pfs28roboto">Pick a colour</span><br /> <button className="btn button-green btn-sm">&nbsp;&nbsp;&nbsp;</button> <button className="btn button-orange btn-sm">&nbsp;&nbsp;&nbsp;</button> <button className="btn button-red btn-sm">&nbsp;&nbsp;&nbsp;</button> <button className="btn button-yellow btn-sm">&nbsp;&nbsp;&nbsp;</button> </div>
                        </div>
                    </div>
                    <hr className="singleline" />



                    <div className="wow fadeInUp" data-wow-delay="0.1s">

                        {renderStep()}

                    </div>



                    <br /><br />


                </div>
            </div>
        </>
    )
}

export default RightPanel
