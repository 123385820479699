import React from 'react'
import { Link } from 'react-router-dom'
import LearnMore from '../ButtonComponent/LearnMore';

function Aerospace() {
  var learnclassText="btn btn-blue mt-3";
  return (
    <>
      <div className="mobilefullbanner">
        <div
          className="container aerospace-banner py-5 mt-5 mb-5 wow fadeIn rounded-40"
          data-wow-delay="0.1s"
        >
          <div className="container text-center pt-5 pb-5 py-5">
            <h1 className="display-2 text-white mt-5 mb-5 hfs48 pt-5 pb-5 animated slideInDown">
              Aerospace
            </h1>
          </div>
        </div>

        <div className="container vertical-align-top">
          <div className="row">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-5 mb-4 hfs36roboto merge-industry">
                Design the spacecraft
                <br /> with AI+AR
              </h1>
            </div>
            <div
              className="col-lg-7 wow fadeInUp merge-power"
              data-wow-delay="0.5s"
            >
              <p className="mb-4 fw-normal pfs24black">
                With Merge & Merge OS, you have the power of AI+AR on your
                finger tips. Use your aircraft designs for AI powered editing,
                design validation, simulation, collaborative reviews,
                demonstrations & so much more! Scale the design to life size &
                get a real sense of space & design constraints you have to work
                with.
              </p>
              <Link to="/Aerospace">
                <LearnMore learnclassText={learnclassText}/>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </>
  )
}

export default Aerospace
