import React from 'react'

function PersonalizedSpatial() {
    return (
        <>
            <div className="container-fluid bg-black">
                <div className="container pb-5">
                    <div className="row g-5 pt-3">
                        <div
                            className="col-lg-6 col-md-12 wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
                                <h1 className="display-2 text-white hfs48 pt-5 animated slideInDown">
                                    Concepts to Finished Products, for everyone
                                </h1>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 col-md-12 wow fadeInUp"
                            data-wow-delay="0.3s"
                        >
                            <div className="service-item position-relative h-100">
                                <div className="rounded">
                                    <p className="mb-4 pfs16-white">
                                        Our vision is to enable anyone irrespective of on'e background, resourrces or skillset
                                        to go from concepts to finished products within days.
                                        
                                        Automate software and hardware development for the next generations.
                                    </p>
                                    <p className="mb-4 pfs16-white">
                                        Augrade was started when we realised the future needs people like us the hackers/innovators/engineers/creators solving the hardest problems & helping others in their pursuits.
                                    </p>
                                    <p className="mb-4 pfs16-white">
                                        Our Vision will be realised over 3 Phases, first of which is re-inventing CAD & empowering everyone to
                                        design, engineer & build tangibe physical things. You could design your own furnitures or eletronic products & 3D print/manufacture that, simply by using our platform.
                                    </p>
                                
                                    
                                </div>
                            </div>
                        </div>
                        <img
                            className="img-fluid pb-5"
                            src="img/personalize.png"
                            alt="augrade"
                        />
                    </div>
                </div>
            </div>
        </>
    ) 
}

export default PersonalizedSpatial
