import React from 'react'

function IntroducingAugrade() {
  return (
    <>
      <div className="container-fluid py-5 mb-5">
        <div className="container howitbanner">
          <div className="row">
            <div
              className="text-center mx-auto wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <p className="pfs22-gray">Simple Platform </p>
              <h3 className="hfs54black">Infinite Possibilities</h3>
            </div>
            {/* <div 
              className="container realestate-banner py-5 mt-5 mb-5 wow fadeIn rounded-40"
              data-wow-delay="0.1s"
            >
              <div className="container text-left pt-5">
                <h1 className="display-2 text-white hfs48 animated slideInDown mt-5 mb-0">
                  Enhance your workflow
                </h1>
                <p className="pfs24 mb-2">
                  Our constraints based AI OS system <br />
                  that can run on all devices to empower <br />
                  you to create, edit/validate & visualise
                  <br /> 3D models
                </p>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </>
  )
}

export default IntroducingAugrade
