import React from 'react'

function Privacy() {
  return (
    <>
      <div className="container-fluid py-5">
        <div className="container howitbanner">
          <div className="row">
            <div
              className="text-center mx-auto wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <p className="pfs22-gray">Privacy & Security </p>
              <h3 className="hfs54black">
                Built To Safeguard Your <br />
                Privacy{" "}
              </h3>
            </div>
            <div
              className="container weprotectyourdata-banner mt-5 mb-5 wow fadeIn rounded-40"
              data-wow-delay="0.1s"
            >
              <div className="container text-left pt-5 p-5">
                <img
                  src="img/we-protect.png"
                  className="img-fluid text-center rounded-top"
                  alt="augrade"
                />

                <h1 className="display-2 text-white hfs48 animated slideInDown mt-5 mb-4">
                  We Protect
                  <br />
                  Your Data
                </h1>
                <p className="pfs24 mb-2">
                  Our system annonymizes the data you feed & no
                  <br /> can trace it back to you or identify your work,
                  <br /> not even us
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Privacy
