import React from 'react'

export default function LearnMore(props) {
    const{learnclassText}= props;
  return (
    <>
      <button
        type="button"
        className={learnclassText}
      >
        Learn more
      </button>
    </>
  )
}
