import React from 'react'

function OurMission() {
    return (
        <>
            <div className="container-fluid our-story">
                <div className="container vertical-align-middle">
                    <div className="row pt-4 pb-4">
                        <div
                            className="col-lg-12 text-center mt-5 mb-5 pt-5 pb-5 wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <p className="pfs24black mb-4">Our Vision,</p>
                            <h1 className="display-5 hfs48black mb-4">
                                Augment Human Capabilities 
    
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurMission
