import React from 'react'
import { Link } from 'react-router-dom'

function NewsRoomDesktop() {
  return (
    <>
      <a
        target="_blank"
        href="https://www.augrade.com/blog/thepivotthatchangeditall.php"
      >
        <div className="flexrowdesktop">
          <a>
            <div className="container pt-5 mb-5">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h1 className="hfs40 mt-4 mb-4 p-3">Latest News</h1>
                  </div>
                </div>
              </div>

              <div className="row p-4">
                <div className="col-lg-8 col-md-12 nopadding">
                  <div className="blog-post rounded">
                    <div className="blog-img d-block overflow-hidden position-relative">
                      <img
                        src="img/pivot.jpeg"
                        className="img-fluid rounded-start"
                        alt="augrade"
                        style={{ height: "380px", width: "104%" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12 nopadding">
                  <div className="blog-post rounded">
                    <div className="content p-4 border">
                      <br />
                      <small className="text-muted p">Update</small>
                      <h4 className="mt-1 mb-4 pb-2">
                        <a
                          href="https://www.augrade.com/blog/thepivotthatchangeditall.php"
                          className="text-dark title"
                        >
                          Announcing
                          <br /> Augrade Dreamspace
                        </a>
                      </h4>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <small className="text-muted p">November 2023</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </a>
    </>
  )
}

export default NewsRoomDesktop
