import React from 'react'
import HeaderSticky from './HeaderSticky';
import AllinOne from './RealEstateComponents/AllinOne';
import AugradeDreamspace from './RealEstateComponents/AugradeDreamspace';
import DesignDreamHomes from './RealEstateComponents/DesignDreamHomes';
import EnhanceDesign from './RealEstateComponents/EnhanceDesign';
import RenderModels from './RealEstateComponents/RenderModels';
import Integrated from './RealEstateComponents/Integrated';
import SpatialComputing from './RealEstateComponents/SpatialComputing';
import RealEstate1 from './RealEstateComponents/RealEstate1';
import RealEstate2 from './RealEstateComponents/RealEstate2';
import Delivering from './HomeComponents/Delivering';
// import RealEstate3 from './RealEstateComponents/RealEstate3';
// import RealEstate4 from './RealEstateComponents/RealEstate4';
import QnA from './RealEstateComponents/QnA';
import Submenu from './RealEstateComponents/Submenu';

export default function RealEstate() {

  function beforeAfter() {
    document.getElementById('compare').style.width = document.getElementById('slider').value + "%";
  }
  return (
    <div> 
      <HeaderSticky />

      <Submenu />

      <AllinOne />

      {/* <AugradeDreamspace /> */}

      <DesignDreamHomes />

      {/* <EnhanceDesign /> */}

      {/* <RenderModels beforeAfter={beforeAfter} /> */}

      {/* <Integrated /> */}

      {/* <SpatialComputing /> */}

      {/* <RealEstate1 /> */}

      {/* <RealEstate2 /> */}

      <Delivering/>

      {/* <RealEstate3 />

      <RealEstate4 /> */}

      <QnA />

      

      {/* <div className="container-fluid py-5">
            <div className="container howit">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="hfs48black">Projects being powered <br/>by Augrade </h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div> */}

      {/* <div className="container-fluid py-5">
            <div className="container howit">
                <div className="row">
                <div className="mx-auto text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h1 className="display-5 mb-4 hfs40roboto">Create photorealistic renders</h1>
                    <img src="img/real-estate-gal-7.png" className="img-fluid rounded-top" alt="augrade"/>
                </div>
                </div>
            </div>
        </div> */}
   
    </div>
  );
}
