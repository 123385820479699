import React from 'react'

function TryForFree(props) {
  const { tryfreeclassText } = props;
  const { leftIcon } = props;
  const { onclickLink } = props;
  return (
    <>
      <button
        type="button"
        className={tryfreeclassText}
        onClick={onclickLink}
      >
        Join Waitlist
        {leftIcon}
      </button>

    </>
  )
}

export default TryForFree
