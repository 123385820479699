import React from 'react'
import { Link } from 'react-router-dom'
import LearnMore from '../ButtonComponent/LearnMore';

function RealEstate() {
    var learnclassText="btn btn-blue mt-3"; 
  return (
    <>
    <div className="mobilefullbanner">
        <div
          className="container realestate-banner py-5 mt-5 mb-5 wow fadeIn rounded-40"
          data-wow-delay="0.1s"
        >
          <div className="container text-center pt-5 pb-5 py-5">
            <h1 className="display-2 text-white mt-5 mb-5 hfs48 pt-5 pb-5 animated slideInDown">
              Real Estate
            </h1>
          </div>
        </div>

        <div className="container vertical-align-top">
          <div className="row">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-5 mb-4 hfs36roboto merge-industry">
                Design homes,
                <br />
                the AI Way
              </h1>
            </div>
            <div
              className="col-lg-7 wow fadeInUp merge-power"
              data-wow-delay="0.5s"
            >
              <p className="mb-4 fw-normal pfs24black ">
                We empower you digitize your 3D models, generate more ideas with
                similar constraints, visualise the dream homes through the new
                reality & enable you to edit/scale/change the home as you see
                fit, on the go. Scale the design to life size & get the real
                sense of space & design constraints you have to work with.
              </p>
              <Link to="/RealEstate">
                <LearnMore learnclassText={learnclassText}/>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  )
}

export default RealEstate
