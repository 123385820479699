import React from 'react'

function CoreValuesDesktop() {
    return (
        <>
            <div className="desktoptable">
                <div className="container mt-5 pt-4">
                    <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="display-5 mb-4 hfs40roboto">Our Core Values</h1>
                    </div>

                    <div className="table-wrap">
                        <table className="table table-responsive table-borderless">
                            <tbody>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                    </td>
                                    <td>
                                        <div className="pfs28roboto">Speed</div>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                                Speed of quality execution is our main advantage. Aiming for & delivering ground breaking solutions faster than anyone else.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                    </td>
                                    <td>
                                        <div className="pfs28roboto">Outside the box</div>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                                When you're deliivering unimaginable things at lightning speed? Processes & traditional approaches won't work, we figure out new ways.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                    </td>
                                    <td>
                                        <div className="pfs28roboto">Privacy & Security</div>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                                Privacy is not negotiable. We strive to keep your matters
                                                private & safe. Full disclosure and openness about what
                                                we collect and how we use it to make your life better so
                                                there are no secrets between us.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                    </td>
                                    <td>
                                        <div className="pfs28roboto">Transparency</div>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                                We communicate openly, listen & learn from each other to deliver results, better & faster together.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                    </td>
                                    <td>
                                        <div className="pfs28roboto">Empower People</div>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                                We help each other excel in what we do. This comes with strong accountability, open & honest communication.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
} 

export default CoreValuesDesktop
