import React from 'react'

function KnowMore(props) {
    const{classText} = props;
    return (
        <>
            <button
                type="button"
                className={classText}
            >
                Know more
            </button>
        </>
    )
}

export default KnowMore