import React from 'react'
import Apply from '../ButtonComponent/Apply';
import Info from '../ButtonComponent/Info';

function OpenPositionsDesktop() {
    var applyclassText="btn btn-black";
    var infoclassText="btn btn-black";
  return (
    <>
      <div className="desktoptable">
            <div className="container mt-5 mb-5">
                <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
                    <h1 className="display-5 mb-5 hfs40roboto">Open Positions</h1>
                </div>
                
                <div className="table-wrap open-position">
                    <table className="table table-responsive table-borderless">
                        <tbody>
                            <tr className="align-middle alert border-bottom" role="alert">
                                <td>
                                    <div className="pfs28roboto">GNN engineer</div>
                                </td>
                                <td>
                                    <div><p className="pfs20 text-center">GNN Team</p></div>
                                </td>
                                <td>
                                    <div><p className="pfs20 text-center">Hybrid<br/>(Bangalore or San Francisco)</p></div>
                                </td>
                                <td>
                                    <div className="text-center"><a href="https://forms.gle/EvjvXR7GfL3nMKACA" target="_blank" >
                                    <Apply applyclassText={applyclassText}/>
                                        </a></div>
                                </td>
                                <td>
                                    <div className="text-center"><a href="https://augrade.notion.site/Join-Augrade-3bea0c252bcc467da51edba3a1646a3c" target="_blank">
                                    <Info infoclassText={infoclassText}/>
                                        </a></div>
                                </td>
                            </tr>
                            <tr className="align-middle alert border-bottom" role="alert">
                                <td>
                                    <div className="pfs28roboto">CAD/3D Engineer</div>
                                </td>
                                <td>
                                    <div><p className="pfs20 text-center">CAD/Geometric Engine Team</p></div>
                                </td>
                                <td>
                                    <div><p className="pfs20 text-center">Hybrid<br/>(Bangalore or San Francisco)</p></div>
                                </td>
                                <td>
                                    <div className="text-center"><a href="https://forms.gle/EvjvXR7GfL3nMKACA" target="_blank" >
                                    <Apply applyclassText={applyclassText}/>
                                        </a></div>
                                </td>
                                <td>
                                    <div className="text-center"><a href="https://augrade.notion.site/Join-Augrade-3bea0c252bcc467da51edba3a1646a3c" target="_blank">
                                    <Info infoclassText={infoclassText}/>
                                        </a></div>
                                </td>
                            </tr>
                           
                            <tr className="align-middle alert border-bottom" role="alert">
                                <td>
                                    <div className="pfs28roboto">Java/C++ Developer</div>
                                </td>
                                <td>
                                    <div><p className="pfs20 text-center">Desktop Application Team</p></div>
                                </td>
                                <td>
                                    <div><p className="pfs20 text-center">Hybrid<br/>(Bangalore or San Francisco)</p></div>
                                </td>
                                <td>
                                    <div className="text-center"><a href="https://forms.gle/EvjvXR7GfL3nMKACA" target="_blank" >
                                    <Apply applyclassText={applyclassText}/>
                                        </a></div>
                                </td>
                                <td>
                                    <div className="text-center"><a href="https://augrade.notion.site/Join-Augrade-3bea0c252bcc467da51edba3a1646a3c" target="_blank">
                                    <Info infoclassText={infoclassText}/>
                                        </a></div>
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
  )
} 

export default OpenPositionsDesktop

