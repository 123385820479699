import React from 'react'
import TryItOut from '../ButtonComponent/TryItOut'

function DesignDreamHomes() {
    var tryitoutclassText = "btn btn-blue mt-4"
    return (
        <>
            <div
                className="container-fluid bg-black mt-4 pt-3 pb-5"
                bis_skin_checked="1"
            >
                <div className="container py-5" bis_skin_checked="1">
                    <div className="row" bis_skin_checked="1">
                        <h1 className="display-6 mb-3 text-white text-center">
                            Augrade Dreamspace enables you to
                            <br />
                            <span className="hfs40gothicblue">Design The Dream Homes</span>
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-lg-6 col-md-12 serviceiconmobiletext wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <p className="pt-4 pfs22-white">Parametric Design & Ideation</p>
                            <h1 className="display-2 text-white pfs32 animated slideInDown">
                                Capture the site & generate 3D models
                            </h1>
                            <p className="pt-4 pfs22-white">
                                Use text or image to generate
                                multiple 3D models with mm
                                level accuracy
                            </p>
                        </div>

                        <div className="row g-4 serviceiconmobile" bis_skin_checked="1">
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp p-0"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/linear-3d-capture.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Text
                                            <br /> to 3D 
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/site-mapping.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Technical
                                            <br /> Accuracy
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/50.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Weeks of work in mins
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-center" bis_skin_checked="1"> 
                            <TryItOut tryitoutclassText={tryitoutclassText} />
                            </div>*/}
                    </div>
                    <div
                        className="col-lg-6 p-0 col-md-12 serviceiconmobileimage text-center wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <img
                                src="img/real-estate-page-1.png"
                                className="img-fluid"
                                alt="augrade"
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5 flexrowdesktop">
                    <div
                        className="col-lg-6 serviceiconmobileimage text-center p-0 col-md-12 wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <img
                                src="img/real-estate-page-2.png"
                                className="img-fluid"
                                alt="augrade"
                            />
                        </div>
                    </div>
                    <div
                        className="col-lg-6 validate col-md-12 serviceiconmobiletext wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <p className="pt-4 pfs22-white">Validation & Editing</p>
                            <h1 className="display-2 text-white pfs32 animated slideInDown">
                                Collaborate & Resolve design conflicts in real time
                            </h1>
                            <p className="pt-4 pfs22-white">
                                Validate your designs based on parameters for
                                structure, electrical, noise etc & collaboratively edit those
                                parts in real time
                            </p>
                        </div>

                        <div className="row g-4 serviceiconmobile" bis_skin_checked="1">
                            <div
                                className="col-lg-4  col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/validate.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Validate
                                            <br /> 2D/3D
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/edit-in.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Edit in
                                            <br /> real-time
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/save-across.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Save across
                                            <br /> tools
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-center" bis_skin_checked="1"> }
                            <TryItOut tryitoutclassText={tryitoutclassText} />
                        </div>*/}
                    </div>
                </div>

                <div className="row mt-5 flexrowmobile">
                    <div
                        className="col-lg-6 validate col-md-12 serviceiconmobiletext wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <p className="pt-4 pfs22-white">Validation & Editing</p>
                            <h1 className="display-2 text-white pfs32 animated slideInDown">
                                Validate & collaboratively edit based on constraints
                            </h1>
                            <p className="pt-4 pfs22-white">
                                Validate your designs based on real-world constraints in
                                structure, electrical, noise etc & collaboratively edit those
                                parts
                            </p>
                        </div>

                        <div className="row g-4 serviceiconmobile" bis_skin_checked="1">
                            <div
                                className="col-lg-4  col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/validate.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Validate
                                            <br /> 2D/3D
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/edit-in.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Edit in
                                            <br /> real-time
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/save-across.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Save across
                                            <br /> tools
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-center" bis_skin_checked="1"> 
                            <TryItOut tryitoutclassText={tryitoutclassText} />
                        </div>*/}
                    </div>
                    <div
                        className="col-lg-6 serviceiconmobileimage text-center p-0 col-md-12 wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <img
                                src="img/real-estate-page-2.png"
                                className="img-fluid"
                                alt="augrade"
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5 mb-5">
                    <div
                        className="col-lg-6 col-md-12 serviceiconmobiletext wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <p className="pt-4 pfs22-white">Render & Showcase</p>
                            <h1 className="display-2 text-white pfs32 animated slideInDown">
                                Demonstrate the dream house to your customers
                            </h1>
                            <p className="pt-4 pfs22-white">
                                Empower your clients to visualise their future homes, not only
                                with photorealistic images but actual walk-throughs in the
                                real-world!
                            </p>
                        </div>

                        <div className="row g-4 serviceiconmobile" bis_skin_checked="1">
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/drag-drop.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Drag &<br /> Drop
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/virtual-walk.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Virtual
                                            <br /> walk thorugh
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/scale-it.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Scale it,
                                            <br /> Shrink it
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-center" bis_skin_checked="1"> }
                            <TryItOut tryitoutclassText={tryitoutclassText} />
                        </div>*/}
                    </div>
                    <div
                        className="col-lg-6 p-0 col-md-12 serviceiconmobileimage text-center wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <img
                                src="img/real-estate-page-3.png"
                                className="img-fluid"
                                alt="augrade"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DesignDreamHomes
