import React from 'react'
import { Link } from 'react-router-dom'
import KnowMore from '../ButtonComponent/KnowMore'

function AugradeNews() {
    var classText = "btn btn-black";
    return (
        <>
            <div className="container-fluid mt-100 mt-60 py-5">
                <div className="container augradenewstitle">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h1 className="hfs48black">Resources & Blog</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container augradenews">
                    <div className="row">
                        {/* <div className="col-lg-4 col-md-4 mt-4 pt-2 home-news-block">
              <div className="blog-post text-left rounded">
                <div className="blog-img d-block overflow-hidden position-relative">
                  <img
                    src="img/blog.png"
                    className="img-fluid rounded-top"
                    alt="augrade"
                  />
                </div>
                <div className="content p-4 border">
                  <h4 className="mt-3 mb-3">
                    <Link to="" className="text-dark title">
                      Augrade secured
                      <br /> $100k Grant
                    </Link>
                  </h4>
                  <small className="text-muted p float-right">
                    July 1, 2023
                  </small>
                  <small>
                    <Link to="" className="text-primary">
                      Company News
                    </Link>
                  </small>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 mt-4 pt-2 home-news-block">
              <div className="blog-post text-left rounded">
                <div className="blog-img d-block overflow-hidden position-relative">
                  <img
                    src="img/blog.png"
                    className="img-fluid rounded-top"
                    alt="augrade"
                  />
                </div>
                <div className="content p-4 border">
                  <h4 className="mt-3 mb-3">
                    <Link to="" className="text-dark title">
                      Augrade’s a part
                      <br /> of the QDIC
                    </Link>
                  </h4>
                  <small className="text-muted p float-right">
                    June 30, 2023
                  </small>
                  <small>
                    <Link to="" className="text-primary">
                      Company News
                    </Link>
                  </small>
                </div>
              </div>
            </div> */}

                        <div className="col-lg-4 col-md-4 mt-4 pt-2 home-news-block">
                            <a
                                target="_blank"
                                href="https://www.augrade.com/blog/thepivotthatchangeditall.php"
                            >
                                <div className="blog-post text-left rounded">
                                    <div className="blog-img d-block overflow-hidden position-relative">
                                        <img
                                            src="img/pivot.jpeg"
                                            className="img-fluid rounded-top"
                                            alt="augrade"
                                        />
                                    </div>
                                    <div className="content p-4 border">
                                        <h4 className="mt-3 mb-3">
                                            <a
                                                href="https://www.augrade.com/blog/thepivotthatchangeditall.php"
                                                className="text-dark title"
                                            >
                                                Announcing
                                                <br /> Augrade Dreamspace
                                            </a>
                                        </h4>
                                        <small className="text-muted p float-right">
                                            November 26, 2023
                                        </small>
                                        <small>
                                            <Link to="" className="text-primary">
                                                Company Update
                                            </Link>
                                        </small>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="text-center mt-4 mb-5 pt-2">
                            <Link to="/NewsRoom">
                                <KnowMore classText={classText}/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AugradeNews
