import React from 'react'

function Info(props) {
    const {infoclassText}=props;
    return (
    <>
      <button
        type="button"
        className={infoclassText}
      >
         Info
      </button>
    </>
  )
}

export default Info