import React from 'react'
import HeaderSticky from './HeaderSticky'
import AllinOne from './AutomotiveComponents/AllinOne'
import AugradeDreamspace from './AutomotiveComponents/AugradeDreamspace'
import EnhanceDesign from './AutomotiveComponents/EnhanceDesign'
import Integrated from './AutomotiveComponents/Integrated'
import SpatialComputing from './AutomotiveComponents/SpatialComputing'
import Automotive1 from './AutomotiveComponents/Automotive1'
import Automotive2 from './AutomotiveComponents/Automotive2'
import Delivering from './HomeComponents/Delivering';
// import Automotive3 from './AutomotiveComponents/Automotive3'
// import Automotive4 from './AutomotiveComponents/Automotive4'
import QnA from './AutomotiveComponents/QnA'
import Submenu from './AutomotiveComponents/Submenu'
import DesignHighPerformanceEv from './AutomotiveComponents/DesignHighPerformanceEv'

export default function Automotive() {
  
  return (
    <div>
      <HeaderSticky />

      <Submenu />

      <AllinOne />

    {/* <AugradeDreamspace /> */}

      <DesignHighPerformanceEv/>

      {/* <EnhanceDesign /> */}

      {/* <Integrated /> */}

      {/* <SpatialComputing /> */}

      {/* <Automotive1 /> */}

      {/* <Automotive2 /> */}
      <Delivering/>

      {/* <Automotive3 />

      <Automotive4 /> */}

      <QnA />



      {/* <div className="container-fluid py-5">
            <div className="container howit">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="hfs48black">Projects being powered<br/> by Augrade </h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black env">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div> */}
      
    </div>
  );
}
