import React from 'react'

function CoreValuesMobile() {
    return (
        <>
            <div className="mobiletable">
                <div className="container mt-5 pt-4">
                    <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="display-5 mb-4 hfs40roboto">Our Core Values</h1>
                    </div>

                    <div className="table-wrap">
                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                        <div className="pfs28roboto mb-3">Speed</div>
                        <p className="pfs20">
                        Speed of quality execution is our main advantage. Aiming for & delivering ground breaking solutions faster than anyone else.
                        </p>
                        <br />

                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                        <div className="pfs28roboto mb-3">Outside the Box</div>
                        <p className="pfs20">
                        When you're deliivering unimaginable things at lightning speed? Processes & traditional approaches won't work, we figure out new ways.

                        </p>
                        <br />

                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                        <div className="pfs28roboto mb-3">Privacy & Security</div>
                        <p className="pfs20">
                            Privacy is not negotiable. We strive to keep your life private &
                            safe. Full disclosure and openness about what we collect and how
                            we use it to make your life better so there are no secrets between
                            us.
                        </p>
                        <br />

                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                        <div className="pfs28roboto mb-3">Transparency</div>
                        <p className="pfs20">
                        We communicate openly, listen & learn from each other to deliver results, better & faster together.
                            
                        </p>
                        <br />

                         {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                        <div className="pfs28roboto mb-3">Empower People</div>
                        <p className="pfs20">
                        We help each other excel in what we do. This comes with strong accountability, open & honest communication.
                        </p>
                        <br />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoreValuesMobile
