import React from 'react'

function Team() {
    return (
        <>
            <div className="container-fluid aisupported mt-5 mb-5">
                <div className="container">
                    <div className="atservice">
                        <div className="mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h1
                                className="display-5 mb-4 hfs40roboto"
                                style={{ marginLeft: "12px" }}
                            >
                                Augrade’s Team
                            </h1>
                        </div>
                        <div className="row g-4"> 
                            
                            <div
                                className="col-lg-4 col-md-12 wow fadeInUp"
                                data-wow-delay="0.1s"
                            >
                                <a href="https://www.linkedin.com/in/keshav-sharma-02/" target="_blank" >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    <img
                                        className="img-fluid rounded-top"
                                        src="img/story1.jpg"
                                        alt="augrade"
                                    />

                                    <div className="team-content pt-3">
                                        <div className="row">
                                            <div className="col-lg-12 px-5">
                                                <h5 className="pfs24black">Keshav Sharma</h5>
                                                <p className="pfs16-black">Founder & CEO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </a>
                            </div>
              
                            
                            <div
                                className="col-lg-4 col-md-12 wow fadeInUp"
                                data-wow-delay="0.3s"
                            >
                                <a href="https://www.linkedin.com/in/krish-parekh-bb3b13197/" target="_blank">
                                <div className="team-item rounded overflow-hidden pb-4">
                                    <img
                                        className="img-fluid rounded-top"
                                        src="img/story2.jpeg"
                                        alt="augrade"
                                    />

                                    <div className="team-content pt-3">
                                        <div className="row">
                                            <div className="col-lg-12 px-5">
                                                <h5 className="pfs24black">Krish Parekh</h5>
                                                <p className="pfs16-black">Co-Founder & CTO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </a>
                            </div>
                        
                            
                            <div
                                className="col-lg-4 col-md-12 wow fadeInUp"
                                data-wow-delay="0.5s"
                            >
                                <a href="https://www.linkedin.com/in/rajith-shetty-77667119a/" target="_blank">
                                <div className="team-item rounded-lg overflow-hidden pb-4">
                                    <img
                                        className="img-fluid rounded-top"
                                        src="img/story3.jpg"
                                        alt="augrade"
                                    />

                                    <div className="team-content pt-3">
                                        <div className="row">
                                            <div className="col-lg-12 px-5">
                                                <h5 className="pfs24black">Rajith Shetty</h5>
                                                <p className="pfs16-black">Co-Founder & SVP Software</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </a>
                            </div>
                    

                            {/* <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item rounded overflow-hidden pb-4">
                            <img className="img-fluid" src="img/story1.jpg" alt="augrade"/>
                            
                            <div className="team-content pt-3">
                                <div className="row">
                                    <div className="col-lg-12 px-5">
                                        <h5 className="pfs24black">Pooja Mishra</h5>
                                        <p className="pfs16-black">Hardware engineer</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="team-item rounded overflow-hidden pb-4">
                            <img className="img-fluid" src="img/story2.jpeg" alt="augrade"/>
                            
                            <div className="team-content pt-3">
                                <div className="row">
                                    <div className="col-lg-12 px-5">
                                        <h5 className="pfs24black">Subodh Satpute</h5>
                                        <p className="pfs16-black">Hardware engineer</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="team-item rounded overflow-hidden pb-4">
                            <img className="img-fluid" src="img/story3.jpg" alt="augrade"/>
                            
                            <div className="team-content pt-3">
                                <div className="row">
                                    <div className="col-lg-12 px-5">
                                        <h5 className="pfs24black">Sameer Singh</h5>
                                        <p className="pfs16-black">CV Engineer</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item rounded overflow-hidden pb-4">
                            <img className="img-fluid" src="img/story1.jpg" alt="augrade"/>
                            
                            <div className="team-content pt-3">
                                <div className="row">
                                    <div className="col-lg-12 px-5">
                                        <h5 className="pfs24black">Manasvi</h5>
                                        <p className="pfs16-black">Gen AI engineer</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="team-item rounded overflow-hidden pb-4">
                            <img className="img-fluid" src="img/story2.jpeg" alt="augrade"/>
                            
                            <div className="team-content pt-3">
                                <div className="row">
                                    <div className="col-lg-12 px-5">
                                        <h5 className="pfs24black">Aryan</h5>
                                        <p className="pfs16-black">AI/ML engineer</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="team-item rounded overflow-hidden pb-4">
                            <img className="img-fluid" src="img/story3.jpg" alt="augrade"/>
                            
                            <div className="team-content pt-3">
                                <div className="row">
                                    <div className="col-lg-12 px-5">
                                        <h5 className="pfs24black">Mohan</h5>
                                        <p className="pfs16-black">AOSP Developer</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Team
