import React from 'react'
import { Link } from 'react-router-dom'

function NewsRoomMobile() {
  return (
    <>
      <div className="flexrowmobile">
        <div className="container pt-5">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h1 className="hfs40 mt-4 p-3">Latest News</h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 col-md-12  news-block">
              <div className="blog-post rounded">
                <div className="blog-img d-block overflow-hidden position-relative ">
                  <img
                    src="img/pivot.jpeg"
                    className="img-fluid rounded-top rounded-larger"
                    alt="augrade"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 news-block">
              <div className="blog-post rounded">
                <div className="content p-4 border">
                  <a
                    href="https://www.augrade.com/blog/thepivotthatchangeditall.php"
                    className="text-dark title"
                  >
                    Announcing
                    <br /> Augrade Dreamspace
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewsRoomMobile
