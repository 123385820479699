import React from 'react'

function Workingmobile() {
    return (
        <>
            <div className="mobiletable">
                <div className="container mt-5 pt-4">
                    {/* <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s"> 
                        <h1 className="display-5 mb-4 hfs40roboto">How it works</h1>
                    </div>*/}
                    <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h2 className="hfs40 mb-5">How it works?</h2>
          </div>

                    <div className="table-wrap">
                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
             
                        <div className="pfs28roboto mb-3">AI Powered CAD Generations</div>
                        <p classname="pfs20">Describe & Upload</p>
                        <p className="pfs20">
                       
                        Describe your requirements/specifications & Upload all relevant files which contain more details on your outcome expected.
                        The model is built within seconds/minutes.
                        </p>
                        <br />

                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}

                        <div className="pfs28roboto mb-3">Editing & Simulations</div>
                        <p classname="pfs20">Edit, Analyse & Customise</p>
                        <p className="pfs20">
                        Using Augrade's AI powered Geometric, Physics & Simulation engines with parametric capabilities anyone can ensure your design is technically feasible & compliant with all regulations. 
                                                 
                        </p>
                        <br />

                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}

                        <div className="pfs28roboto mb-3">Collaborate Effectively</div>
                        <p classname="pfs20">Cross-tool & Multi-team</p>
                        <p className="pfs20">
                        We enable multiple teams to interact & use design files across tools in real time.               
                        </p>
                        <br />

                        
                    </div> 
                </div>
            </div>
        </>
    )
}

export default Workingmobile
