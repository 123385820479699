import React from 'react'
import HeaderSticky from './HeaderSticky'
import AllinOne from './AerospaceComponents/AllinOne'
import AugradeDreamspace from './AerospaceComponents/AugradeDreamspace'
import SpatialComputing from './AerospaceComponents/SpatialComputing'
import DesignSpaceTech from './AerospaceComponents/DesignSpaceTech'
import EnhanceDesign from './AerospaceComponents/EnhanceDesign'
import Integrated from './AerospaceComponents/Integrated'
import Aerospace1 from './AerospaceComponents/Aerospace1'
import Delivering from './HomeComponents/Delivering';
// import Aerospace3 from './AerospaceComponents/Aerospace3'
// import Aerospace4 from './AerospaceComponents/Aerospace4'
import Aerospace5 from './AerospaceComponents/Aerospace5'
import Submenu from './AerospaceComponents/Submenu'

export default function Aerospace() {

  const imageStyle = {
    width: "90%",
    marginTop: "15px",
  };
  return (
    <div> 
      
      <HeaderSticky />

      <Submenu />

      <AllinOne />

      {/* <AugradeDreamspace /> */}

      <DesignSpaceTech />

      {/* <EnhanceDesign /> */}

      {/* <Integrated /> */}

      {/* <SpatialComputing /> */}

      {/* <Aerospace1 /> */}

      {/* <Aerospace2 /> */}
      <Delivering/>

      {/* <Aerospace3 />

      <Aerospace4 /> */}

      <Aerospace5 />


      {/* <div className="container-fluid py-5">
            <div className="container howit">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="hfs48black">Projects being powered<br/> by Augrade </h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item possibilities position-relative h-100">
                            <p className="pt-4 pfs24gothic-black">Environment</p>
                            <div className="float-right" style={{top:"280px", right:"30px"}}>
                                <img src="img/Vector.png" alt="augrade"/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div> */}

    </div>
  );
}
