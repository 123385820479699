import React from 'react'
import { Link } from 'react-router-dom'

function Submenu() {
  return (
    <>
      <div
        className="container-fluid pt-3 sticky-top"
        style={{ borderBottom: "1px solid #eee" }}
      >
        <div className="container howit">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <h6 className="hfs20black">Career</h6>
            </div>
            <div className="col-lg-8 col-md-12 display-inline right-menus">
              <ul className="merge-submenu">
                <Link to="/OurStory">
                  {" "}
                  <li className="menu1">Our Story</li>
                </Link>
                <Link to="/Newsroom">
                  {" "}
                  <li className="menu1">Newsroom</li>
                </Link>
                <Link to="/ContactUs">
                  {" "}
                  <li className="menu1">Contact Us</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Submenu
