import React from 'react'

function QnA() {

    return (
        <>
            <div className="container-fluid py-5 mb-5">
                <div className="container howit">
                    <div
                        className="text-center mx-auto wow fadeInUp"
                        data-wow-delay="0.1s"
                    >
                        <h3 className="hfs48black mb-4">Question? Answers</h3>
                    </div>
                    <div className="row">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button pfs20roboto collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        What is Dreamspace?
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body pfs16-black">
                                    Dreamspace is an AI platform to automate CAD design and create a digital
                                        twin of your satellite, car or house to enhance your
                                        design/engineering/manufacturing workflows
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className="accordion-button pfs20roboto collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                        Do you support other design tools?
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body pfs16-black">
                                        Dreamspace is 2-way cross-compatible with all design tools,
                                        meaning all changes across any design tool will be seen
                                        across Dreamspace.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className="accordion-button pfs20roboto collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                        Do I need to buy Merge Glasses for Dreamspace?
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body pfs16-black">
                                        No, Dreamspace is available on our wesbite to try & our
                                        webapp can be used across mobile/desktop/laptop. Though a
                                        Merge device wil improve cross collaboration & editing for
                                        all your teams via simple hand-gestures.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button
                                        className="accordion-button pfs20roboto collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                        How is it different from other CAD tools?
                                    </button>
                                </h2>
                                <div
                                    id="collapseFour"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body pfs16-black">
                                        Dreamspace automates menial & heavy design/engineering tasks
                                        whist ensuring cross-communication & compatibility across
                                        all your design tools. It really brings everything to one
                                        platform for you to work with enhancing your workflow. I.e
                                        you can get the benefits of all design on one platform.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSix">
                                    <button
                                        className="accordion-button pfs20roboto collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseSix"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                        What are the benefits of using Dreamspace?
                                    </button>
                                </h2>
                                <div
                                    id="collapseSix"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body pfs16-black">
                                        We help you get 4 weeks work done in 2 hrs or less.
                                        Moreover, using Dreamspacce is like using your mobile phone,
                                        no learning curve, high accuracy, save time & money without
                                        comprimsing on quality of the output.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QnA
