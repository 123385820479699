import React from 'react'

function FindPlans() {
    return (
        <>
            <div className="container mb-5 mt-5 pb=5">
                <div className="row">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                        <h3 className="hfs48black mb-5">Find the best plans for your needs</h3>
                    </div>
                    <div className="col-lg-4 pt-5 price-item">
                        <div className="text-center">
                            <div className="nav price-tabs" role="tablist">
                                <a className="nav-link active" href="#starteryearly" role="tab" data-toggle="tab"><strong>Pay Monthly</strong><br /><span>Commit annually</span></a>
                                <a className="nav-link" href="#startermonthly" role="tab" data-toggle="tab"><strong>Pay Upfront</strong> <br /><span>Commit annually</span></a>
                            </div>
                        </div>


                        <div className="tab-content wow fadeIn" >
                            <div role="tabpanel" className="tab-pane fade show active" id="starteryearly">
                                <div className="row justify-content-center">
                                    <div className="mb-30">
                                        <div className="">
                                            <div className="price-content">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <br />
                                                        <br />
                                                        <h4>Starter</h4>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <p className="mb-0 text-end">Starts at</p>
                                                        <h2 className="mb-0 text-end">$45/mo</h2>
                                                        <p className="text-end">billed at <del>$600</del> $540/yr</p>
                                                    </div>
                                                </div>
                                                <p className="">Essential maeketing, sales, customer service, cms, and operations software.</p>
                                                <a href="#" className="btn btn-custom">Buy now</a>
                                                <br /><br />
                                                <p><strong>Includes:</strong></p>
                                                <p className="mb-0"><i className="fa fa-bolt" aria-hidden="true"></i> <strong>Marketing Hub Starter</strong></p>
                                                <p>Starts at 1,000 marketing contacts</p>
                                                <p className="mb-0"><i className="fa fa-star" aria-hidden="true"></i> <strong>Sales Hub Starter</strong></p>
                                                <p>Starts at 2 paid users</p>
                                                <p className="mb-0"><i className="fa fa-heart" aria-hidden="true"></i> <strong>Service Hub Starter</strong></p>
                                                <p>Starts at 2 paid users</p>
                                                <p><i className="fa fa-cog" aria-hidden="true"></i> <strong>CMS Hub Starter</strong> <span className="badge bg-success">New</span></p>

                                                <p><i className="fa fa-server" aria-hidden="true"></i> <strong>Operations Hub Starter</strong> <span className="badge bg-success">New</span></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade" id="startermonthly">
                                <div className="row justify-content-center">
                                    <div className="mb-30">
                                        <div className="">
                                            <div className="price-content">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <br />
                                                        <br />
                                                        <h4>Starter</h4>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <p className="mb-0 text-end">Starts at</p>
                                                        <h2 className="mb-0 text-end">$95/mo</h2>
                                                        <p className="text-end">billed at <del>$900</del> $840/yr</p>
                                                    </div>
                                                </div>
                                                <p className="">Essential maeketing, sales, customer service, cms, and operations software.</p>
                                                <a href="#" className="btn btn-custom">Buy now</a>
                                                <br /><br />
                                                <p><strong>Includes:</strong></p>
                                                <p className="mb-0"><i className="fa fa-bolt" aria-hidden="true"></i> <strong>Marketing Hub Starter</strong></p>
                                                <p>Starts at 1,000 marketing contacts</p>
                                                <p className="mb-0"><i className="fa fa-star" aria-hidden="true"></i> <strong>Sales Hub Starter</strong></p>
                                                <p>Starts at 2 paid users</p>
                                                <p className="mb-0"><i className="fa fa-heart" aria-hidden="true"></i> <strong>Service Hub Starter</strong></p>
                                                <p>Starts at 2 paid users</p>
                                                <p><i className="fa fa-cog" aria-hidden="true"></i> <strong>CMS Hub Starter</strong> <span className="badge bg-success">New</span></p>

                                                <p><i className="fa fa-server" aria-hidden="true"></i> <strong>Operations Hub Starter</strong> <span className="badge bg-success">New</span></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="col-lg-4 pt-5 price-item">
                        <div className="text-center">
                            <div className="nav price-tabs" role="tablist">
                                <a className="nav-link active" href="#professionalyearly" role="tab" data-toggle="tab"><strong>Pay Monthly</strong><br /><span>Commit annually</span></a>
                                <a className="nav-link" href="#professionalmonthly" role="tab" data-toggle="tab"><strong>Pay Upfront</strong> <br /><span>Commit annually</span></a>
                            </div>
                        </div>



                        <div className="tab-content wow fadeIn" >
                            <div role="tabpanel" className="tab-pane fade show active" id="professionalyearly">
                                <div className="row justify-content-center">
                                    <div className="mb-30">
                                        <div className="">
                                            <div className="price-content">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <br />
                                                        <br />
                                                        <h4>Professional</h4>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <p className="mb-0 text-end">Starts at</p>
                                                        <h2 className="mb-0 text-end">$1,600/mo</h2>
                                                        <p className="text-end">billed at <del>$21,360</del> $19,200/yr</p>
                                                    </div>
                                                </div>
                                                <p className="">Advanced maeketing, sales, customer service, cms, and operations software.</p>
                                                <a href="#" className="btn btn-custom">Talk to Sales</a>
                                                <br /><br />
                                                <p><strong>Includes:</strong></p>
                                                <p className="mb-0"><i className="fa fa-bolt" aria-hidden="true"></i> <strong>Marketing Hub Starter</strong></p>
                                                <p>Starts at 2,000 marketing contacts</p>
                                                <p className="mb-0"><i className="fa fa-star" aria-hidden="true"></i> <strong>Sales Hub Starter</strong></p>
                                                <p>Starts at 5 paid users</p>
                                                <p className="mb-0"><i className="fa fa-heart" aria-hidden="true"></i> <strong>Service Hub Starter</strong></p>
                                                <p>Starts at 5 paid users</p>
                                                <p><i className="fa fa-cog" aria-hidden="true"></i> <strong>CMS Hub Starter</strong></p>

                                                <p><i className="fa fa-server" aria-hidden="true"></i> <strong>Operations Hub Starter</strong> <span className="badge bg-success">New</span></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade" id="professionalmonthly">
                                <div className="row justify-content-center">
                                    <div className="mb-30">
                                        <div className="">
                                            <div className="price-content">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <br />
                                                        <br />
                                                        <h4>Professional</h4>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <p className="mb-0 text-end">Starts at</p>
                                                        <h2 className="mb-0 text-end">$2,600/mo</h2>
                                                        <p className="text-end">billed at <del>$31,360</del> $29,200/yr</p>
                                                    </div>
                                                </div>
                                                <p className="">Advanced maeketing, sales, customer service, cms, and operations software.</p>
                                                <a href="#" className="btn btn-custom">Talk to Sales</a>
                                                <br /><br />
                                                <p><strong>Includes:</strong></p>
                                                <p className="mb-0"><i className="fa fa-bolt" aria-hidden="true"></i> <strong>Marketing Hub Starter</strong></p>
                                                <p>Starts at 2,000 marketing contacts</p>
                                                <p className="mb-0"><i className="fa fa-star" aria-hidden="true"></i> <strong>Sales Hub Starter</strong></p>
                                                <p>Starts at 5 paid users</p>
                                                <p className="mb-0"><i className="fa fa-heart" aria-hidden="true"></i> <strong>Service Hub Starter</strong></p>
                                                <p>Starts at 5 paid users</p>
                                                <p><i className="fa fa-cog" aria-hidden="true"></i> <strong>CMS Hub Starter</strong></p>

                                                <p><i className="fa fa-server" aria-hidden="true"></i> <strong>Operations Hub Starter</strong> <span className="badge bg-success">New</span></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="col-lg-4 pt-5 price-item">
                        <div className="text-center">
                            <div className="nav price-tabs" role="tablist">
                                <a className="nav-link active" href="#enterpriseyearly" role="tab" data-toggle="tab"><strong>Pay Monthly</strong><br /><span>Commit annually</span></a>
                                <a className="nav-link" href="#enterprisemonthly" role="tab" data-toggle="tab"><strong>Pay Upfront</strong> <br /><span>Commit annually</span></a>
                            </div>
                        </div>


                        <div className="tab-content wow fadeIn" >
                            <div role="tabpanel" className="tab-pane fade show active" id="enterpriseyearly">
                                <div className="row justify-content-center">
                                    <div className="mb-30">
                                        <div className="">
                                            <div className="price-content">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <br />
                                                        <br />
                                                        <h4>Enterprise</h4>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <p className="mb-0 text-end">Starts at</p>
                                                        <h2 className="mb-0 text-end">$5,000/mo</h2>
                                                        <p className="text-end">billed at $60,000/yr</p>
                                                    </div>
                                                </div>
                                                <p className="">Our maeketing, sales, customer service, cms, and operations software.</p>
                                                <a href="#" className="btn btn-custom">Talk to Sales</a>
                                                <br /><br />
                                                <p><strong>Includes:</strong></p>
                                                <p className="mb-0"><i className="fa fa-bolt" aria-hidden="true"></i> <strong>Marketing Hub Starter</strong></p>
                                                <p>Starts at 10,000 marketing contacts</p>
                                                <p className="mb-0"><i className="fa fa-star" aria-hidden="true"></i> <strong>Sales Hub Starter</strong></p>
                                                <p>Starts at 10 paid users</p>
                                                <p className="mb-0"><i className="fa fa-heart" aria-hidden="true"></i> <strong>Service Hub Starter</strong></p>
                                                <p>Starts at 10 paid users</p>
                                                <p><i className="fa fa-cog" aria-hidden="true"></i> <strong>CMS Hub Starter</strong></p>

                                                <p><i className="fa fa-server" aria-hidden="true"></i> <strong>Operations Hub Starter</strong> <span className="badge bg-success">New</span></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade" id="enterprisemonthly">
                                <div className="row justify-content-center">
                                    <div className="mb-30">
                                        <div className="">
                                            <div className="price-content">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <br />
                                                        <br />
                                                        <h4>Enterprise</h4>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <p className="mb-0 text-end">Starts at</p>
                                                        <h2 className="mb-0 text-end">$15,000/mo</h2>
                                                        <p className="text-end">billed at $60,000/yr</p>
                                                    </div>
                                                </div>
                                                <p className="">Our maeketing, sales, customer service, cms, and operations software.</p>
                                                <a href="#" className="btn btn-custom">Talk to Sales</a>
                                                <br /><br />
                                                <p><strong>Includes:</strong></p>
                                                <p className="mb-0"><i className="fa fa-bolt" aria-hidden="true"></i> <strong>Marketing Hub Starter</strong></p>
                                                <p>Starts at 10,000 marketing contacts</p>
                                                <p className="mb-0"><i className="fa fa-star" aria-hidden="true"></i> <strong>Sales Hub Starter</strong></p>
                                                <p>Starts at 10 paid users</p>
                                                <p className="mb-0"><i className="fa fa-heart" aria-hidden="true"></i> <strong>Service Hub Starter</strong></p>
                                                <p>Starts at 10 paid users</p>
                                                <p><i className="fa fa-cog" aria-hidden="true"></i> <strong>CMS Hub Starter</strong></p>

                                                <p><i className="fa fa-server" aria-hidden="true"></i> <strong>Operations Hub Starter</strong> <span className="badge bg-success">New</span></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default FindPlans
