import React from 'react'

function BookCall(props) {
  const { bookclassText } = props;
  const { onclickLink } = props;
  return (
    <>
      <button
        type="button"
        className={bookclassText}
        onClick={onclickLink}
      >
        Book a call
      </button>
    </>
  )
}

export default BookCall
