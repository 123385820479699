import React from 'react'

function Integrated() {
  return (
    <>
      <div className="container-fluid bg-gray-integrated workflow flexrowdesktop">
        <div className="container">
          <div
            className="mx-auto text-center wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h1 className="display-5 mb-4 hfs54roboto">
              Integrated into
              <br /> your workflow
            </h1>
            <p className="pfs24gothic-black">
              Use Any design tool any file format
              <br />
              for editing & see the changes reflected here & viceversa
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-gray-integratedmobile workflow flexrowmobile">
        <div className="container">
          <div
            className="mx-auto text-center wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h1 className="display-5 mb-4 hfs54roboto">
              Integrated into
              <br /> your workflow
            </h1>
            <p className="pfs24gothic-black">
              Use Any design tool any file format
              <br />
              for editing & see the changes reflected here & viceversa
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Integrated
