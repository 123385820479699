import React from 'react'

function Welcome() {
    return (
        <>
            <div className="container-xxl contact-us" bis_skin_checked="1">
                <div className="container vertical-align-middle" bis_skin_checked="1">
                    <div className="row g-5 pb-4" bis_skin_checked="1">
                        <div className="col-lg-2"></div>
                        <div
                            className="col-lg-8 text-center mb-5 pb-5 wow fadeInUp"
                            data-wow-delay="0.1s"
                            style={{
                                visibility: "visible",
                                animationDelay: "0.1s",
                                animationName: "fadeInUp",
                            }}
                            bis_skin_checked="1"
                        >
                            <h1 className="display-5 hfs48black pt-5 pb-5">
                                Welcome To Augrade
                                <br /> Support
                            </h1>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Welcome
