import React from 'react'
import { Link } from 'react-router-dom'
import HeaderSticky from './HeaderSticky'
import Submenu from './CareerComponents/Submenu'
import TherePlace from './CareerComponents/TherePlace'
import CoreValuesDesktop from './OurStoryComponents/CoreValuesDesktop';
import CoreValuesMobile from './OurStoryComponents/CoreValuesMobile';
import OpenPositionsDesktop from './CareerComponents/OpenPositionsDesktop'
import OpenPositionsMobile from './CareerComponents/OpenPositionsMobile'

export default function Career() {
  return (
    <div>
        <HeaderSticky />

        <Submenu />
         
        <TherePlace/>

        <CoreValuesDesktop/>

        <CoreValuesMobile/>

        <OpenPositionsDesktop/>

        <OpenPositionsMobile/>
        
        
        

        
        
        

        


    </div>
  )
}
