import React from 'react'
import TryItOut from '../ButtonComponent/TryItOut'

function DesignHighPerformanceEv() {
    var tryitoutclassText = "btn btn-blue mt-4"
    return (
        <>
            <div
                className="container-fluid bg-black mt-4 pt-3 pb-5"
                bis_skin_checked="1"
            >
                <div className="container py-5" bis_skin_checked="1">
                    <div className="row" bis_skin_checked="1">
                        <h1 className="display-6 mb-3 text-white text-center">
                            Augrade Dreamspace enables you to
                            <br />
                            <span className="hfs40gothicblue">
                                Design High Performace EVs
                            </span>
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-lg-6 col-md-12 serviceiconmobiletext wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <p className="pt-4 pfs22-white">Design & Ideate</p>
                            <h1 className="display-2 text-white pfs32 animated slideInDown">
                                Generate 3D models of components or EVs
                            </h1>
                            <p className="pt-4 pfs22-white">
                                Automate the 3D model creation of various components using
                                reference images & speed-up your design time
                            </p>
                        </div>

                        <div className="row g-4 serviceiconmobile" bis_skin_checked="1">
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/linear-3d-capture.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Mm level accuracy
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/site-mapping.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Constraint
                                            <br /> based
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/50.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Time saved in sketch to CAD conversion
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-center" bis_skin_checked="1"> 
                            <TryItOut tryitoutclassText={tryitoutclassText} />
                        </div>*/}
                    </div>
                    <div
                        className="col-lg-6 p-0 col-md-12 serviceiconmobileimage text-center wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <img
                                src="img/automotive2.png"
                                className="img-fluid"
                                alt="augrade"
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5 flexrowdesktop">
                    <div
                        className="col-lg-6 p-0 col-md-12 text-center serviceiconmobileimage wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <img
                                src="img/automotive3.png"
                                className="img-fluid"
                                alt="augrade"
                            />
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-md-12 validate serviceiconmobiletext wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <p className="pt-4 pfs22-white">Validation & Editing</p>
                            <h1 className="display-2 text-white pfs32 animated slideInDown">
                                Validate & collaboratively edit based on constraints
                            </h1>
                            <p className="pt-4 pfs22-white">
                                Validate your designs based on real-world constraints in weight,
                                crash-testing, aerodynamics etc & collaboratively edit those
                                parts, together
                            </p>
                        </div>

                        <div className="row g-4 serviceiconmobile" bis_skin_checked="1">
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/validate.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Validate
                                            <br /> 2D/3D
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/edit-in.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Edit in
                                            <br /> real-time
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/save-across.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Save across
                                            <br /> tools
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-center" bis_skin_checked="1"> 
                            <TryItOut tryitoutclassText={tryitoutclassText} />
                        </div>*/}
                    </div>
                </div>

                <div className="row mt-5 flexrowmobile">
                    <div
                        className="col-lg-6 col-md-12 validate serviceiconmobiletext wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <p className="pt-4 pfs22-white">Validation & Editing</p>
                            <h1 className="display-2 text-white pfs32 animated slideInDown">
                                Validate & collaboratively edit based on constraints
                            </h1>
                            <p className="pt-4 pfs22-white">
                                Validate your designs based on real-world constraints in weight,
                                crash-testing, aerodynamics etc & collaboratively edit those
                                parts, together
                            </p>
                        </div>

                        <div className="row g-4 serviceiconmobile" bis_skin_checked="1">
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/validate.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Validate
                                            <br /> 2D/3D
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/edit-in.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Edit in
                                            <br /> real-time
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/save-across.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Save across
                                            <br /> tools
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-center" bis_skin_checked="1"> 
                            <TryItOut tryitoutclassText={tryitoutclassText} />
                        </div>*/}
                    </div>
                    <div
                        className="col-lg-6 p-0 col-md-12 text-center serviceiconmobileimage wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <img
                                src="img/automotive3.png"
                                className="img-fluid"
                                alt="augrade"
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5 mb-5">
                    <div
                        className="col-lg-6 col-md-12 serviceiconmobiletext wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <p className="pt-4 pfs22-white">Manufacturing & Assembly</p>
                            <h1 className="display-2 text-white pfs32 animated slideInDown">
                                Identify faults early in manufacturing vs design
                            </h1>
                            <p className="pt-4 pfs22-white">
                                Use the 3D model & cross check the manufactured part to ensure
                                it’s all as per the design you finalised, eliminating 3rd party
                                issues
                            </p>
                        </div>

                        <div className="row g-4 serviceiconmobile" bis_skin_checked="1">
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/drag-drop.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Drag &<br /> Drop
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/virtual-walk.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Virtual
                                            <br /> walk thorugh
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay="0.5s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.5s",
                                    animationName: "fadeInUp",
                                }}
                                bis_skin_checked="1"
                            >
                                <div
                                    className="service-item position-relative h-100"
                                    bis_skin_checked="1"
                                >
                                    <div className="rounded" bis_skin_checked="1">
                                        <div
                                            className="btn-square rounded-circle mx-auto mb-5 mt-5"
                                            bis_skin_checked="1"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="img/scale-it.png"
                                                alt="augrade"
                                            />
                                        </div>
                                        <h5 className="pfs22-white text-center">
                                            Scale it,
                                            <br /> Shrink it
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-center" bis_skin_checked="1"> 
                            <TryItOut tryitoutclassText={tryitoutclassText} />
                        </div>*/}
                    </div>
                    <div
                        className="col-lg-6 p-0 col-md-12 serviceiconmobileimage text-center wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInUp",
                        }}
                        bis_skin_checked="1"
                    >
                        <div className="" bis_skin_checked="1">
                            <img
                                src="img/automotive4.png"
                                className="img-fluid"
                                alt="augrade"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DesignHighPerformanceEv
