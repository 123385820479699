import React from "react";
import HeaderSticky from "./HeaderSticky";
import Submenu from "./MergeComponents/Submenu";
import AugradeMerge from "./MergeComponents/AugradeMerge";
import BeyondReality from "./MergeComponents/BeyondReality";
import RealEstate from "./MergeComponents/RealEstate";
import Aerospace from "./MergeComponents/Aerospace";
import Automobile from "./MergeComponents/Automobile";
import Introducing from "./MergeComponents/Introducing";
import MergeOs from "./MergeComponents/MergeOs";
import Powerful from "./MergeComponents/Powerful";
import SpatialComputing from "./MergeComponents/SpatialComputing";
import Privacy from "./MergeComponents/Privacy";
import Tougher from "./MergeComponents/Tougher";
import Possibilities from "./MergeComponents/Possibilities";
import AtYourService from "./MergeComponents/AtYourService";
import WaitingFor from "./MergeComponents/WaitingFor";
import QuestionAnswer from "./MergeComponents/QuestionAnswer";

export default function Merge() {
  return (
    <div>
      <HeaderSticky />
      
      <Submenu/>

      <AugradeMerge/>

      <BeyondReality/>
      
      <RealEstate/>
      
      <Aerospace/>
      
      <Automobile/>

      <Introducing/>
      
      <MergeOs/>
      
      <Powerful/>
      
      <SpatialComputing/>
      
      <Privacy/>

      <Tougher/>
      
      <Possibilities/>
      
      <AtYourService/>
      
      <WaitingFor/>

      <QuestionAnswer/>
      
    </div>
  );
}
