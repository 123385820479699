import React from 'react'

function Section1() {
    return (
        <>
            <div className="container py-5 mt-4">
                <div className="row">
                    <div className="col-lg-2">

                    </div>
                    <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
                        <h1 className="display-5 mb-4 hfs40-bloggray">Section1 of article</h1>
                        <p className="mb-4 fw-normal pfs24gothic-bloggray">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                        <p className="mb-4 fw-normal pfs24gothic-bloggray">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                        <h2 className="display-5 pt-4 mb-4 pfs32gothicblack">A title for this particular section of the article</h2>
                        <p className="mb-4 fw-normal pfs24gothic-black">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                        <p className="mb-4 fw-normal pfs24gothic-black">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                        <p className="mb-4 fw-normal pfs24gothic-black">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                        <p className="mb-4 fw-normal pfs24gothic-black">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                    </div>
                    <div className="col-lg-2">

                    </div>
                </div>
            </div>

            <div className="container-xxl" bis_skin_checked="1">
                <div className="container">
                    <img src="img/blogpage2.png" className="img-fluid" alt="augrade" />
                </div>
            </div>

            <div className="container py-5 mt-4">
                <div className="row">
                    <div className="col-lg-2">

                    </div>
                    <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
                        <p className="mb-4 fw-normal pfs24gothic-black">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                        <p className="mb-4 fw-normal pfs24gothic-black">Technological advancements constantly push the human race futher and to do better. Snapinsight Focuses on the use of technology to break innovative barriers and push the human race further, to help, empower and entertain everyone.</p>
                    </div>
                    <div className="col-lg-2">

                    </div>
                </div>
            </div>
        </>
    )
}

export default Section1
