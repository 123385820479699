import React from 'react'

function Possibilities() {
  return (
    <>
        <div className="container-fluid py-5 mb-5">
        <div className="container possible">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h3 className="hfs54black">
              The Possibilities are
              <br /> Limitless{" "}
            </h3>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs24gothic-black">Create 3D models</p>
              </div>
            </div>
            <div
              className="col-lg-8 col-md-12 mt-5 text-center wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs24gothic-black">
                  Validate the designs & 3D Models
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div
              className="col-lg-12 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs32gothicblack">
                  Perform simulations in AR
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-8 col-md-12 mt-5 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs24gothic-black">
                  Edit with simple hand
                  <br /> gestures
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs24gothic-black">
                  Scale to room size & walkaround the 3D models
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div
              className="col-lg-12 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs32gothicblack">
                  Verify the 3D models against manufactured parts
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Possibilities
