import React from 'react'
import HeaderSticky from './HeaderSticky'
import Submenu from './NewsRoomComponents/Submenu';
import NewsRoomDesktop from './NewsRoomComponents/NewsRoomDesktop';
import NewsRoomMobile from './NewsRoomComponents/NewsRoomMobile';
import NewsRoom1 from './NewsRoomComponents/NewsRoom1';
import FilterArticles from './NewsRoomComponents/FilterArticles'

export default function NewsRoom() {
    return (
        <div>
            <HeaderSticky />

            <Submenu />

            <NewsRoomDesktop />

            <NewsRoomMobile />

            {/* <NewsRoom1 />

            <FilterArticles /> */}


        </div>
    );
}
