import React from 'react'

function BeyondReality() {
  return (
    <>
      <div className="container-fluid pb-5 bg-black">
        <div className="pb-5 pt-5 howit">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div
              id="carouselExampleDark"
              className="carousel carousel-dark slide gobeyond"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="2000">
                  <div className="carousel-caption d-md-block">
                    <h1 className="display-2 text-white hfs48 pt-5 animated slideInDown">
                      Go Beyond Reality
                    </h1>
                    <p className="fw-normal pfs20 text-white">
                      Merge the digital & physical worlds,
                      <br />
                      seamlessly
                    </p>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="carousel-caption d-md-block">
                    <h1 className="display-2 text-white hfs48 pt-5 animated slideInDown">
                      Go Beyond Reality
                    </h1>
                    <p className="fw-normal pfs20 text-white">
                      Merge the digital & physical worlds,
                      <br />
                      seamlessly
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="carousel-caption d-md-block">
                    <h1 className="display-2 text-white hfs48 pt-5 animated slideInDown">
                      Go Beyond Reality
                    </h1>
                    <p className="fw-normal pfs20 text-white">
                      Merge the digital & physical worlds,
                      <br />
                      seamlessly
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <h1 className="display-2 text-white hfs48 pt-5 animated slideInDown">Go Beyond Reality</h1>
                <p className="fw-normal pfs20 text-white">Merge the digital & physical worlds,<br/>
                    seamlessly</p> */}
            <div className="flexrowdesktop row-7-el mt-5 pt-5">
              <div className="element margintop200px">
                <img
                  src="img/gobeyond5.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop130px">
                <img
                  src="img/gobeyond3.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element">
                <img
                  src="img/gobeyond5.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element"></div>

              <div className="element">
                <img
                  src="img/gobeyond8.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop130px">
                <img
                  src="img/gobeyond10.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop200px">
                <img
                  src="img/gobeyond8.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop200px">
                <img
                  src="img/gobeyond6.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop130px">
                <img
                  src="img/gobeyond4.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element">
                <img
                  src="img/gobeyond6.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop130px">
                <img
                  src="img/gobeyond7.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element">
                <img
                  src="img/gobeyond9.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop130px">
                <img
                  src="img/gobeyond11.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop200px">
                <img
                  src="img/gobeyond9.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>
            </div>

            {/* mobile code */}

            <div className="flexrowmobile row-7-el">
              <div className="element">
                <img
                  src="img/gobeyond5.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element"></div>

              <div className="element">
                <img
                  src="img/gobeyond8.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element">
                <img
                  src="img/gobeyond6.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element margintop130px">
                <img
                  src="img/gobeyond7.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>

              <div className="element">
                <img
                  src="img/gobeyond9.png"
                  className="img-fluid"
                  alt="augrade"
                />
              </div>
            </div>
            {/* mobile code */}
          </div>
        </div>
      </div>
    <br/>
    <br/>
    </>
  )
}

export default BeyondReality
