import React from 'react'

function BlogPage1() {
  return (
    <>
        <div className="container py-5 mt-4">
            <div className="row">
                <div className="col-lg-2">
                    
                </div>
                <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
                    <p className="mb-4 fw-normal text-center pfs24gothic-gray">Technology | October 2020</p>
                    <h1 className="display-5 mb-4 hfs40roboto">A blog title that is amazing</h1>
                    <p className="mb-4 fw-normal text-center pfs24gothic-gray">Social media icosn to share this article link</p>
                </div>
                <div className="col-lg-2">
                </div>
            </div>
        </div>

        <div className="container-xxl" bis_skin_checked="1">
            <img src="img/blogpage1.png" className="img-fluid" alt="augrade"/>
        </div>
    </>
  )
}

export default BlogPage1
