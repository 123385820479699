import React from 'react'

function SpatialComputing() {
  return (
    <>
      <div className="container-fluid bg-black pt-3 pb-5" bis_skin_checked="1">
        <div className="container">
          <div className="py-5" bis_skin_checked="1">
            <div className="row" bis_skin_checked="1">
              <h1 className="display-6 mb-3 text-white hfs54white text-center">
                Pushing the limits of
                <br />
                <span className="hfs54blue">Spatial Computing</span>
              </h1>

              <div
                className="col-lg-12 col-md-12 mb-2 text-center wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  src="img/pushing-the-limits.png"
                  className="img-fluid rounded-top"
                  alt="augrade"
                />
              </div>
              <h1 className="display-6 mb-3 text-white text-center hfs54white">
                Compact & <br />
                miniaturised design
              </h1>
              <p className="pfs20gothic text-center">
                A powerhouse of specialised technologies in
                <br /> Displays + Processors + Batteries & more!!!
              </p>
            </div>

            <div
              className="row mt-5"
              style={{ border: "1px solid #fff" }}
              bis_skin_checked="1"
            >
              <div
                className="col-lg-12 col-md-12 mb-2 text-center wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  src="img/merge2.png"
                  className="img-fluid rounded-top"
                  alt="augrade"
                />
              </div>
              <div
                className="col-lg-8 col-md-8 mb-2 text-center wow fadeInUp merge-hand"
                data-wow-delay="0.1s"
              ></div>
              <div
                className="col-lg-4 col-md-4 mb-2 wow fadeInUp merge-hand"
                data-wow-delay="0.1s"
              >
                <h1 className="display-6 mb-3 text-white">
                  Hand Tracking <br />
                  At It’s Best
                </h1>
                <br />
                <p className="pfs20gothic">
                  Built with special breakthroughs in camera & CV models to
                  enhance human-machine interaction{" "}
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row mt-5" style={{ border: "1px solid #fff" }}>
              <div className="row mt-5 p-5">
                <div
                  className="col-lg-4 col-md-4 mb-2 text-left wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <h1 className="display-5 ms-5 mb-3 hfs36robotowhite">
                    Charge once <br />
                    get 5+ hrs life
                  </h1>
                  <img
                    src="img/merge3.png"
                    className="img-fluid rounded-top"
                    alt="augrade"
                  />
                </div>
                <div
                  className="col-lg-4 col-md-4 mb-2 text-center wow fadeInUp"
                  data-wow-delay="0.1s"
                ></div>
                <div
                  className="col-lg-4 col-md-4 mb-2 text-left wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <h1 className="display-5 mb-3 hfs36robotowhite">
                    Charge at least <br />
                    twice
                  </h1>
                  <img
                    src="img/whiter.png"
                    className="img-fluid rounded-top"
                    alt="augrade"
                  />
                </div>
              </div>

              <div className="row">
                <div
                  className="col-lg-4 col-md-4 mb-2 text-center wow fadeInUp"
                  data-wow-delay="0.1s"
                ></div>
                <div
                  className="col-lg-4 col-md-4 mb-2 text-center wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <h1 className="display-6 mb-3 text-white text-left ">
                    Dual Charging <br />
                    system
                  </h1>
                  <br />
                  <p className="pfs20gothic text-lrft">
                    Charge Merge or your mobiles or <br />
                    airpods with the case at least twice!
                  </p>
                </div>
                <div
                  className="col-lg-4 col-md-4 mb-2 text-center wow fadeInUp"
                  data-wow-delay="0.1s"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SpatialComputing
