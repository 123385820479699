import React from 'react'
import TryForFree from '../ButtonComponent/TryForFree';
import BookCall from '../ButtonComponent/BookCall';
import WatchDemoVideo from '../ButtonComponent/WatchDemoVideo';

function AllinOne() {
    var tryfreeclassText = "btn btn-dark";
    var leftIcon = <i className="bi bi-chevron-right ms-2"></i>;
    var bookclassText = "btn btn-primary";
    var watchdemoclassText = "btn btn-primary btn100width text-center";
    var onclickLink = () =>
        (window.location.href = "https://forms.gle/cSp2GHjgHdQMVRLD8");
    return (
        <>
            <div className="container-fluid bg-gray">
                <div className="container vertical-align-middle howit">
                    <div className="row">
                        <div
                            className="col-lg-6 mt-5 mb-5 pt-5 pb-5 wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <p className="pfs20">Augrade Dreamspace</p>
                            <h1 className="display-5 mb-4">
                                AI Platform
                                <br /> For Building
                                <br /> <span className="text-purple">Homes</span>
                            </h1>
                            <p className="pfs20">
                                Text/image to 3D coordination, with technical accuracy
                            </p>

                            <br />

                            <TryForFree
                                tryfreeclassText={tryfreeclassText}
                                onclickLink={onclickLink}
                                leftIcon={leftIcon}
                            />

                            <BookCall
                                bookclassText={bookclassText}
                                onclickLink={onclickLink}
                            />

                            <div className="videobutton">
                                <WatchDemoVideo watchdemoclassText={watchdemoclassText} />
                            </div>
                        </div>

                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <img
                                className="img-fluid"
                                src="img/real-estate-page.png"
                                alt="augrade"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllinOne
