import React from 'react'

function TherePlace() {
    return (
        <>
            <div className="container-fluid career" bis_skin_checked="1">
                <div className="container vertical-align-middle howit" bis_skin_checked="1">
                    <div className="row pt-4" bis_skin_checked="1">
                        <div className="col-lg-6 mt-5 mb-5 pt-5 pb-5 wow fadeInUp" data-wow-delay="0.1s" bis_skin_checked="1" style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp" }}>
                            <h1 className="display-5 mb-4">There’s Place For<br /> Everyone</h1>
                            <p className="pfs20roboto">Even your pets</p>
                            <br />
                            <p className="pfs20roboto">At Augrade, we belive you can’t do your best work sourrounded by limitations.</p>
                            <p className="pfs20roboto">Our solution? We work on the most exciting & challenging problems to build the future.</p>

                        </div>

                        <div className="col-lg-6 mt-5 mb-5 pt-5 pb-5 wow fadeInUp" data-wow-delay="0.5s" bis_skin_checked="1" style={{ visibility: "visible", animationDelay: "0.5s", animationName: "fadeInUp" }}>
                            <img className="img-fluid" src="img/career1.png" alt="augrade" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TherePlace
