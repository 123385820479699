import React from 'react'

function BuyNow(props) {
    const{btnclassText} = props;
    return (
        <>
        <button
            type="button"
            className={btnclassText}
        >
            Buy Now
        </button>
        </>
    )
}

export default BuyNow
