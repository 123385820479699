import React from 'react'
import { Link } from 'react-router-dom'
import HeaderSticky from './HeaderSticky'
import OurMission from './OurStoryComponents/OurMission';
import PersonalizedSpatial from './OurStoryComponents/PersonalizedSpatial';
import CoreValuesDesktop from './OurStoryComponents/CoreValuesDesktop';
import CoreValuesMobile from './OurStoryComponents/CoreValuesMobile';
import Team from './OurStoryComponents/Team';
import Partners from './OurStoryComponents/Partners';
import Investors from './OurStoryComponents/Investors';
import Submenu from './OurStoryComponents/Submenu';

export default function OurStory() {
  return (
    <div>
      <HeaderSticky />

      <Submenu />

      <OurMission /> 

      <PersonalizedSpatial />

      <CoreValuesDesktop />

      <CoreValuesMobile />

      <Team />

      <Partners />

      <Investors />
      
    </div>
  );
}
