import React from 'react'

function Apply(props) {
    const {applyclassText}=props;
    return (
    <>
      <button
        type="button"
        className={applyclassText}
      >
        Apply
      </button>
    </>
  )
}

export default Apply
