import React from 'react'

function Tougher() {
  return (
    <>
        <div className="container-fluid py-5">
        <div className="container howit">
          <div className="row">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="display-5 mb-4 hfs48roboto">
                Tougher Than <br />
                Tough
              </h1>
            </div>
            <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
              <p className="mb-4 fw-normal pfs22-gray">
                Built for usage across the board, indoors or outdoors. The
                sturdy material ensures every component inside is safe no matter
                how it falls.
              </p>
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className="col-lg-3 col-md-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded">
                  <p className="pfs32roboto mb-3">
                    Crack
                    <br /> Resistent
                  </p>
                  <p className="mb-4 fw-normal pfs22-gray">Built to last</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded">
                  <p className="pfs32roboto mb-3">
                    Water
                    <br /> Resistent
                  </p>
                  <p className="mb-4 fw-normal pfs22-gray">Even under water</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item position-relative h-100">
                <div className="rounded">
                  <p className="pfs32roboto mb-3">
                    Dust
                    <br /> Resistent
                  </p>
                  <p className="mb-4 fw-normal pfs22-gray">
                    Clear vision, throughout
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            ></div>
          </div>
          <br />
          <div className="row g-5">
            <div className="col-lg-1"></div>

            <div
              className="col-lg-10 col-md-10 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <img
                src="img/tougher-than-tough.png"
                className="img-fluid rounded-top"
                alt="augrade"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tougher
