import React from 'react'
// import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from 'react';
import { useState } from 'react';
import Header from './Header';
import QnA from './MergePricingComponents/QnA';
import RightPanel from './MergePricingComponents/RightPanel';


export default function MergePricing() {
    
    var [defaultPrice, setDefaultPrice] = useState(1249);
    var [defaultDiscount, setDefaultDiscount] = useState(1049);
    const [step, setStep] = useState(1);

    const handleNext = () => {
        if (step < 3) {
          setStep(step + 1);
        }
    };

    const handleBack = () => {
        if (step > 1) {
          setStep(step - 1);
        }
    };

    const renderStep = () => {
        switch (step) {
          case 1:
            return (
              <div>
                <div className="wow fadeInUp mt-4" data-wow-delay="0.5s">
                     <p className="pfs28roboto">Buy Industry specific Dreamspace <span className="pfs16-black">( You can buy later )</span></p>
                    
                     <div id="btnShowHideReal" className={divClassActiveRealEstate} tabindex="-1" onClick={(e)=>handleRealEstate(e)}>
                         <p className="pfs24">Real estate</p>
                     </div>

                     <div id="btnShowHideAerospace" className={divClassActiveAerospace} tabindex="-1" onClick={(e)=>handleAerospace(e)}>
                     <p className="pfs24">Aerospace</p>
                 </div>

                    <div id="btnShowHideAutomobile" className={divClassActiveAutomotive} tabindex="-1" onClick={(e)=>handleAutomotive(e)}>
                        <p className="pfs24">Automobile</p>
                    </div>
                 </div>

                <div className="text-center">
                    {/* <button class="btn btn-dark mt-4" disabled={showIndustryButton} onClick={(e) => { setStep(step + 1) }}>Continue</button> */}
                    <button class="btn btn-dark mt-4" disabled={showIndustryButton} onClick={handleNext}>Continue</button>
                </div>
              </div>
            );
          case 2:
            return (
              <div>
                <div className="stepprevious wow fadeInUp mt-4" data-wow-delay="0.1s">
                     {/* <button className="pfs20roboto" onClick={()=>{setStep(step-1)}}><i class="fa fa-chevron-left"> </i>&nbsp; Edit Industry</button> */}
                     <button className="pfs20roboto" onClick={handleBack}><i class="fa fa-chevron-left"> </i>&nbsp; Edit Industry</button>
                 </div>

                 <div id="divShowHide" className="wow fadeInUp" data-wow-delay="0.1s">
                     {/* <a href="#divformshow"> */}
                         <div className="row">
                             <div className="col-lg-12 vertical-align-middle">
                                 <div className="mt-5 text-center">
                                     <p className="pfs28roboto">Which plan do you want <br /> <span className="pfs16-black">( You can change later )</span></p>
                                 </div>
                                 <div id="btnShowHideReal" className={divClassActiveStarter} onClick={()=>handleStarterPlan()} tabindex="-1" style={{ border: "1px solid #000", borderRadius: "40px" }}>
                                     <div className="col-lg-6">
                                         <p className="pfs28roboto">Starter Plan</p>
                                     </div>
                                     <div className="col-lg-6" style={{ textAlign: "right" }}>
                                        <p className="pfs28roboto">${starterPlan} <br /> <span className="pfs16-black">/yr/user</span></p>
                                     </div>
                                 </div>
                             </div>

                         </div>
                 {/* </a> */}
                     {/* <a href="#divformshow"> */}
                         <div className="row">
                                                {/* Here Web Plan */}
                             <div id="btnShowHideAerospace" className="col-lg-12 vertical-align-middle">
                                <div className={divClassActivePro}  tabindex="-1" onClick={(e)=>handleProPlan(e)} style={{ border: "1px solid #000", borderRadius: "40px" }}>
                                     <div className="col-lg-6">
                                         <p className="pfs28roboto">Pro Plan</p>
                                     </div>
                                    <div className="col-lg-6" style={{ textAlign: "right" }}>
                                        <p className="pfs28roboto">${proPlan} <br /> <span className="pfs16-black">/yr/user</span></p>
                                     </div>
                                </div>
                             </div>

                         </div>
                     {/* </a> */}
                     <div className="row mt-4 text-center pt-4 pb-3 px-4" style={{ border: "1px solid #000", borderRadius: "40px" }}>

                         <div className="form-check">
                             <input className="form-check-input" type="checkbox" id="gridCheck1" />
                             <label className="form-check-label pfs20roboto" for="gridCheck1">I don’t want the industry specific software now</label>
                         </div>
                     </div>
                 </div>

                <div className="text-center">
                     {/* <button onClick={() => { setStep(step - 1) }}>Previous</button> */}
                     {/* <button className="btn btn-dark mt-4" disabled={showButton} onClick={() => { setStep(step + 1) }}>Order Now</button> */}
                     <button className="btn btn-dark mt-4" disabled={showButton} onClick={handleNext}>Order Now</button>
                 </div>
              </div>
            );
          case 3:
            return (
              <div>
               <div className="stepprevious wow fadeInDown mt-4" data-wow-delay="0.1s">
                     {/* <button className="pfs20roboto" onClick={(e)=>{setStep(step-1)}}><i class="fa fa-chevron-left"> </i>&nbsp; Edit Plan</button> */}
                     <button className="pfs20roboto" onClick={handleBack}><i class="fa fa-chevron-left"> </i>&nbsp; Edit Plan</button>
                     </div>

                 <div className="row mt-5 wow fadeInUp" id="divformshow" data-wow-delay="0.1s">
                     <h4 className='mt-2'>Contact form</h4>
                     <hr />
                     <form onSubmit={saveUser} className='pt-5'>
                         <div className="row g-3">

                             <div className="form-group row mb-4" bis_skin_checked="1">
                                 <label for="inputName" className="col-sm-3 col-form-label pfs20roboto"><strong>Name</strong></label>
                                 <div className="col-sm-9" bis_skin_checked="1">
                                     <input type="text" name="first_name" value={first_name} onChange={(e) => { setFirstname(e.target.value) }} className="form-control" id="firstname" placeholder="Please enter your first name" />
                                 </div>
                             </div>
                             <br /><br />

                             <div className="form-group row mb-4" bis_skin_checked="1">
                                 <label for="inputEmail" className="col-sm-3 col-form-label pfs20roboto"><strong>Email</strong></label>
                                 <div className="col-sm-9" bis_skin_checked="1">
                                     <input type="email" name="email_id" value={email_id} onChange={(e) => { setEmailid(e.target.value) }} className="form-control" id="emailid" placeholder="Please enter your email id" />
                                 </div>
                             </div>
                             <br /><br />

                             <div className="form-group row mb-4" bis_skin_checked="1">
                                 <label for="inputCompany" className="col-sm-3 col-form-label pfs20roboto"><strong>Company</strong></label>
                                 <div className="col-sm-9" bis_skin_checked="1">
                                     <input type="text" name="company_name" value={company_name} onChange={(e) => { setCompanyname(e.target.value) }} className="form-control" id="companyname" placeholder="Please enter your company name" />
                                 </div>
                             </div>
                             <br /><br />

                             <div className="form-group row mb-4" bis_skin_checked="1">
                                 <label for="inputMobile" className="col-sm-3 col-form-label pfs20roboto"><strong>Mobile</strong></label>
                                 <div className="col-sm-9" bis_skin_checked="1">
                                     <input type="text" name="mobile_number" value={mobile_number} onChange={(e) => { setMobilenumber(e.target.value) }} className="form-control" id="mobilenumber" placeholder="Please enter your mobile number" />
                                 </div>
                             </div>
                             <br /><br />

                             <div className="col-12 text-center">
                                    <button className="btn btn-dark rounded-pill px-5" disabled={priceButtonShow}  type="submit">Submit</button>
                             </div>
                         </div>
                    </form>
                 </div>
              </div>
            );
          default:
            return null;
        }
    };





    
    var price = 1249;
    var discount_price = 1049;
    var [proPlan, setProPlan] = useState(1299);
    var starterPlan = 599;
    const [count, setCount] = useState(1);
    const [show, setShow] = useState(false);

    var [showButton, setShowButton] = useState(true);

    var [showIndustryButton, setShowIndustryButton] = useState(true);


    var[divClassActivePro, setDivClassActivePro]= useState("row mt-4 pt-4 px-4");
    var [divClassActiveStarter,setDivClassActiveStarter] = useState("row mt-4 pt-4 px-4");
    var[divClassActiveRealEstate, setDivClassActiveRealEstate]= useState("row product-realestate text-center mt-4");
    var[divClassActiveAerospace, setDivClassActiveAerospace]= useState("row product-aerospace text-center mt-4");
    var[divClassActiveAutomotive, setDivClassActiveAutomotive]= useState("row product-automobile text-center mt-4");
    

    const FORM_ENDPOINT = "https://practical-roentgen.31-187-70-64.plesk.page/api/Api.php";

    const [first_name, setFirstname] = useState("");
    const [email_id, setEmailid] = useState("");
    const [company_name, setCompanyname] = useState("");
    const [mobile_number, setMobilenumber] = useState("")


    const data = {
        first_name: first_name,
        email_id: email_id,
        company_name: company_name,
        mobile_number: mobile_number
    };

    var [priceButtonShow,setpriceButtonShow]= useState(true)

    useEffect(() => {
        canBeSubmitted();
    },[first_name,email_id,company_name, mobile_number]);

    const canBeSubmitted = () => {
        const isValid =
          data.first_name.trim().length &&
          data.email_id.trim().length &&
          data.company_name.trim().length && 
          data.mobile_number.trim().length 
    
        if (first_name!=='' && email_id!=='' && company_name!=='' && mobile_number!=='' ) {
            setpriceButtonShow(false);
        }
        else{
            setpriceButtonShow(true);
        }
    };

      


    


    function saveUser(e) {
        
        e.preventDefault();
        let data = { first_name, email_id, company_name, mobile_number }

        fetch(FORM_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => {
            
        }).then((responseJson) => {
            if (responseJson.status === 200 && responseJson.message === "Mail sent") {
                
            } else {
               
                console.error("API Error:", responseJson.message);
            }
        }).catch((error) => {
            // Handle network or other errors here
            console.error("Error:", error);
        });

        


    }


    
    function incrementValue() {
        var value = parseInt(document.getElementById('number').value, 10);
        value = isNaN(value) ? 0 : value;
        if (value < 10) {
            value++;
            
            document.getElementById('number').value = value;
        }
    }
    function decrementValue() {
        var value = parseInt(document.getElementById('number').value, 10);
        value = isNaN(value) ? 0 : value;
        if (value > 1) {
            value--;
            document.getElementById('number').value = value;
        }

    }

    const increment = () => {
        setCount((prevCount) => prevCount + 1);
    }
    const decrement = () => {
        if (count > 1) {
            setCount((prevCount) => prevCount - 1);
        }
    };
   
    defaultPrice = defaultPrice * count;
    var discount = parseInt(price) - 200;
    defaultDiscount = parseInt(defaultPrice) - 200;
    
    var [prevPrice, setPrevPrice] = useState(0);
    var [prevPlan, setPrevPlan] = useState('');
    var [priceWithPlan, setPriceWithPlan]=useState(0);
    var [proClickedFlag, setProClickedFlag] = useState(false);
    var [starterClickFlag, setStarterClickedFlag] = useState(false);
    var [realEstateClickedFlag, setRealEstateClickedFlag] = useState(false);
    var [aerospaceClickedFlag, setAerospaceClickedFlag] = useState(false);
    var [automotiveClickedFlag, setAutomotiveClickedFlag] = useState(false);

    
    const handleProPlan=(e)=>{
        e.preventDefault();
        if(!proClickedFlag){
            setDefaultPrice(defaultPrice + proPlan - prevPlan);
            setPrevPlan(proPlan);
            
            setDefaultDiscount(defaultDiscount + proPlan - prevPlan);
            setProClickedFlag(true)
            setStarterClickedFlag(false)
            if(showButton){
                setShowButton(!showButton)
            }
            
            setDivClassActivePro("row mt-4 pt-4 px-4 checked");
            setDivClassActiveStarter("row mt-4 pt-4 px-4");
        }
        
        
    }

    const handleStarterPlan=()=>{
        if(!starterClickFlag){
            setDefaultPrice(defaultPrice + starterPlan - prevPlan);
            setPrevPlan(starterPlan);

            setDefaultDiscount(defaultDiscount + starterPlan - prevPlan);
            setStarterClickedFlag(true)
            setProClickedFlag(false)
            if(showButton){
                setShowButton(!showButton)
            }
            setDivClassActiveStarter("row mt-4 pt-4 px-4 checked");
            setDivClassActivePro("row mt-4 pt-4 px-4");
        }
    }

    const handleRealEstate=()=>{
        
        if(!realEstateClickedFlag){
            
            setRealEstateClickedFlag(true)
            setAerospaceClickedFlag(false)
            setAutomotiveClickedFlag(false)
            if(showIndustryButton){
                setShowIndustryButton(!showIndustryButton)
            }
            
            setDivClassActiveRealEstate("row product-realestate text-center mt-4 checked");
            setDivClassActiveAerospace("row product-aerospace text-center mt-4");
            setDivClassActiveAutomotive("row product-automobile text-center mt-4");
        }
        
        
    }

    const handleAerospace=(e)=>{
        e.preventDefault();
        if(!aerospaceClickedFlag){
            
            setAerospaceClickedFlag(true)
            setAutomotiveClickedFlag(false)
            setRealEstateClickedFlag(false)
            if(showIndustryButton){
                setShowIndustryButton(!showIndustryButton)
            }
            
            setDivClassActiveRealEstate("row product-realestate text-center mt-4");
            setDivClassActiveAerospace("row product-aerospace text-center mt-4 checked");
            setDivClassActiveAutomotive("row product-automobile text-center mt-4");
        }
        
        
    }

    const handleAutomotive=(e)=>{
        e.preventDefault();
        if(!automotiveClickedFlag){
            
            setAutomotiveClickedFlag(true)
            setRealEstateClickedFlag(false)
            setAerospaceClickedFlag(false)
            if(showIndustryButton){
                setShowIndustryButton(!showIndustryButton)
            }
            
            setDivClassActiveRealEstate("row product-realestate text-center mt-4");
            setDivClassActiveAerospace("row product-aerospace text-center mt-4");
            setDivClassActiveAutomotive("row product-automobile text-center mt-4 checked");
        }
        
        
    }
    
    return (
        <div>
            <Header />
            <div id="mobileprice">
            <button class="mobilepricebelow"><strong>${defaultPrice}</strong> Total Price  | <strong>${defaultDiscount}</strong> After Potential Discount</button>
                {/* <button class="mobilepricebelow"><strong>${price}</strong> Total Price  | <strong>${discount}</strong> After Potential Discount</button> */}
            </div>

            <div className="super_container">
                <div className="single_product">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 order-lg-2 order-1" style={{ paddingLeft: "100px" }}>

                                <div className="image_selected" style={{ background: "#8E7D7D", padding: "100px 20px 120px 10px" }}><img src="img/front-view-w-logo.png" className="img-fluid" alt="augrade" /></div>
                                <div className="overlappedprice" style={{ marginTop: "-20px", zIndex: "9", color: "#fff", marginLeft: "130px", marginRight: "130px" }}>
                                    <p className="text-center pfs20roboto" style={{ background: "#F7F7F7", borderRadius: "40px", padding: "15px 30px 15px 30px" }}> ${defaultPrice} Total Price | ${defaultDiscount} After Potential Discount</p>
                                </div>
                            </div>

                            <RightPanel increment={increment} decrement={decrement} renderStep={renderStep} count={count}/>
                        
                        </div>

                    </div>
                </div>
            </div>

            <QnA/>

            
        </div>
    )
}
