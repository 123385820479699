import React from 'react'

function WaitingFor() {
  return (
    <>
      <div className="container-fluid py-5 mt-5">
        <div className="container wait">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h3 className="hfs48black">What are you waiting for?</h3>
          </div>
          <div className="row g-5 waitrowmobile" style={{ marginTop: "3rem" }}>
            <div
              className="col-lg-6 pb-5 bg-box-shadow wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div
                className="text-center mx-auto wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h3 className="pfs28roboto">Try it out, for free</h3>
              </div>
              <form className="waitingfor text-center mt-3 mb-3">
                <div className="form-group">
                  <label for="exampleFormControlInput1" className="mb-3">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Please enter your email id"
                  />
                </div>
              </form>
              <p className="color-blue text-center px-5">
                Try XYZ across use cases & devices
              </p>
              <p className="text-center mb-4 pfs16-black">
                Mac, Windows, Android & iOS
              </p>
            </div>

            <div
              className="col-lg-6 pb-5 bg-box-shadow wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div
                className="text-center mx-auto wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h3 className="pfs28roboto">Talk to Our Team</h3>
              </div>
              <form className="waitingfor text-center mt-3 mb-3">
                <div className="form-group">
                  <label for="exampleFormControlInput1" className="mb-3">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Please enter your email id"
                  />
                </div>
              </form>
              <p className="color-blue text-center px-5">
                Purchase & level up with the SW & Device
              </p>
              <p className="text-center mb-4 pfs16-black">
                Mac, Windows, Android & iOS
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WaitingFor
