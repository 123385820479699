import React from 'react'
import { Link } from 'react-router-dom'

function Submenu() {
    return (
        <>
            <div
                className="container-fluid pt-3 sticky-top"
                style={{ borderBottom: "1px solid #eee" }}
            >
                <div className="container howit">
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <h6 className="hfs20black">AEC</h6>
                        </div>
                        <div className="col-lg-8 col-md-12 display-inline right-menus">
                            <ul className="merge-submenu">
                                {/* <Link to="/Merge">
                  {" "}
                  <li className="menu1">Overview</li>
                </Link> */}
                                <Link to="">
                                    {" "}
                                    <li
                                        className="menu1"
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                    >
                                        Pricing
                                    </li>
                                </Link>
                                {/* <Link to="/MergePricing"> <li className="menu1">Pricing</li></Link> */}
                                <Link to="/ContactUs">
                                    {" "}
                                    <li className="menu1">Try it Out</li>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Submenu
