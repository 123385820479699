import React from 'react'

function Investors() {
    return (
        <>
            <div className="container-fluid aisupported mt-5 mb-5">
                <div className="container">
                    <div className="atservice">
                        <div className="mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h1
                                className="display-5 mb-4 hfs40roboto"
                                style={{ marginLeft: "12px" }}
                            >
                                Investors
                            </h1>
                        </div>
                        <div className="row g-4">
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.1s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    {/* <img className="img-fluid" src="img/partner1.png" alt="augrade"/> */}
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.3s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    <img
                                        className="img-fluid"
                                        src="img/investor1.png"
                                        alt="augrade"
                                    />
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.5s"
                            >
                                <div className="team-item rounded overflow-hidden pt-5 pb-4">
                                    <img
                                        className="img-fluid"
                                        src="img/investor2.png"
                                        alt="augrade"
                                    />
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-12 wow fadeInUp"
                                data-wow-delay="0.7s"
                            >
                                <div className="team-item rounded overflow-hidden pb-4">
                                    {/* <img className="img-fluid" src="img/dreamspace1.png" alt="augrade"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Investors
