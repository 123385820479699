import React from 'react'

function Delivering() {
    return (
        <>
            <div className="row delivering">
                <div
                    className="container col-md-9 col-lg-9 below-banner py-5 mt-5 mb-5 wow fadeIn rounded-40"
                    data-wow-delay="0.1s"
                >
                    <div className="container text-center py-5">
                        <h1 className=" display-2 text-white mb-4 hfs48 pt-5 pb-3 animated slideInDown">
                            Delivering Unparalled
                            <br /> Results
                        </h1>
                    </div>
                </div>
            </div>

            <div className="container timesaved">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="display-5 mb-4 hfs48blue">
                            6x
                            <br /> Faster
                        </h1>
                       {/*} <p className="mb-4 fw-normal pfs24black"> 
                            By automating manual
                            <br /> tasks do weeks of work 
                            <br /> in hrs
    </p> */}
                    </div>
                    <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.5s">
                        <h1 className="display-5 mb-4 hfs48blue">
                            3x
                            <br />
                            Cheaper
                        </h1>
                        {/* <p className="mb-4 fw-normal pfs24black"> 
                            Save 20% direct cost per project
                            <br /> & boost your time to completion
                            <br /> flexibly
                        </p>*/}
                    </div>
                    
                    <div className="col-lg-1"></div>
                </div>
            </div>
        </>
    )
}

export default Delivering
