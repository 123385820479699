import React from 'react'

function Scale() {
    const imageStyle = { 
        width: "90%",
        marginTop: "15px"
      };
  return (
    <>
      <div className="container-fluid">
        <div className="container howit">
          <div className="row">
            <div
              className="col-lg-4 col-md-12 mt-5 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs24gothic-black">
                  Scale & Edit with
                  <br /> hand gestures
                </p>
                <p className="pfs16">
                  Scale any 3D model to room size
                  <br /> ( or larger ) & get a detailed sense of space
                </p>
                <img
                  src="img/3d-image.gif"
                  class="img-fluid img-max rounded m-30"
                  alt="augrade"
                  style={imageStyle}
                />
              </div>
            </div>
            <div
              className="col-lg-8 col-md-12 mt-5 text-center wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs24gothic-black">Collaboratively Design</p>
                <p className="pfs16">
                  Design together with your team, resolve design conflicts,
                  <br /> set constraints in real time
                </p>
                <img
                  src="img/collabDesign.jpeg"
                  className="img-fluid rounded mw-80"
                  alt="augrade"
                  style={{
                    width: "auto",
                    height: "65%",
                    marginTop: "10px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div
              className="col-lg-12 text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item possibilities position-relative h-100">
                <p className="pt-4 pfs32gothicblack">
                  Perform simulations in AR
                </p>
                <p className="pfs16">
                  You can validate your designs with the manufactured part,
                  avoid
                  <br /> human/manufacturing errors & improve iteration
                  efficiency
                </p>
                <img
                  src="img/aerospace3.png"
                  className="img-fluid rounded mw-80"
                  alt="augrade"
                  style={{
                    width: "auto",
                    height: "65%",
                    marginTop: "10px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Scale
